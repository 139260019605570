import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListenerAuthRoutingModule } from './listener-auth/listener-auth-routing.module';
import { PageNotFoundComponentComponent } from '../../../app-core/src/app/components/page-not-found-component/page-not-found-component.component';
import { MainLayoutComponent } from './components/main-layout/main-layout.component';
import { HomeComponent } from './pages/home/home.component';
import { ChatComponent } from './pages/chat/chat.component';
import {
  redirectLoggedInTo,
  redirectUnauthorizedTo,
} from '@angular/fire/auth-guard';
import { AngularFireAuthGuard } from '@angular/fire/compat/auth-guard';
import { PostsRoutingModule } from 'projects/app-core/src/app/posts/posts-routing.module';
import { SessionRoutingModule } from 'projects/app-core/src/app/session/session-routing.module';
import { TalksModule } from 'projects/app-core/src/app/talks/talks.module';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectLoggedIn = () => redirectLoggedInTo(['']);

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => ListenerAuthRoutingModule,
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectLoggedIn,
    },
  },
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
    children: [
      {
        path: '',
        component: HomeComponent,
      },
      {
        path: 'chat',
        component: ChatComponent,
      },
      {
        path: 'posts',
        loadChildren: () => PostsRoutingModule,
      },
      {
        path: 'talks',
        loadChildren: () => TalksModule,
      },
      {
        path: 'sessions/therapist',
        loadChildren: () => SessionRoutingModule,
      },
    ],
  },
  { path: '**', pathMatch: 'full', component: PageNotFoundComponentComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import { EmailRequest, LoginRequest, OtpRequest, ResetPasswordRequest } from 'app/models/common-models';
import { Observable, combineLatest, forkJoin, map } from 'rxjs';
import { environment } from '../../environments/environment';
import IAnyoListener from '../models/IAnyoListener';
import {
  NetworkUtilsService,
  URL_DICT,
} from '../../../../app-core/src/service/network-utils.service';
import { MasterData } from '../../../../app-core/src/models/masterData';

@Injectable({
  providedIn: 'root',
})
export class ListenerService {
  protected basePath = environment.serviceUrl;
  constructor(
    protected httpClient: HttpClient,
    protected apiService: NetworkUtilsService,
  ) {}

  public lastSeenlistenerStamp(listenerId: string) {
    return this.apiService.post(URL_DICT.listenerLastSeenUrl + `${listenerId}`);
  }

  public getListener(): Observable<IAnyoListener> {
    return forkJoin({
      listener: this.apiService.get<IAnyoListener>(URL_DICT.getListenerByEmail),
      masterData: this.apiService.get<MasterData>(URL_DICT.getMasterData),
    }).pipe(
      map((value) => {
        const avatarName = value.masterData.avatarNames.filter(
          (name) => name.m_id === value.listener.avtaarName,
        );
        const avatarImage = value.masterData.avatarImages.filter(
          (name) => name.m_id === value.listener.avtaar,
        );

        const listenerSpecialization = value.listener.specialization.map(
          (sp) =>
            value.masterData.listenerSpecialization.filter(
              (ms) => ms.m_id == sp,
            )[0].data,
        );

        value.listener.avtarImage =
          avatarImage[0]?.data || value.masterData.avatarImages[0].data;
        value.listener.avtarName =
          avatarName[0]?.data || value.masterData.avatarNames[0].data;
        value.listener.specializationReadable = listenerSpecialization;

        return value.listener;
      }),
    );
  }
}

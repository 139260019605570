import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddComponent } from './add/add.component';
import { ManageComponent } from './manage/manage.component';
import { ViewComponent } from './view/view.component';
import { AddRecipeComponent } from './add-recipe/add-recipe.component';

const routes: Routes = [
  {
    path: '',
    component: ManageComponent,
  },
  {
    path: 'add',
    component: AddComponent,
  },
  {
    path: 'view/:id',
    component: ViewComponent,
  },
  {
    path: 'edit/:id',
    component: ViewComponent,
  },
  {
    path:'recipe/add',
    component: AddRecipeComponent
  },
  {
    path:'recipe/add/:id',
    component: AddRecipeComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ContentRoutingModule {}

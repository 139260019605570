import { inject, Injectable } from '@angular/core';
import { AuthService } from '../../../../app-core/src/auth/auth.service';
import {
  AnyoFirestoreService,
  FirestoreCollection,
  FirestoreSubCollection,
} from '../../../../app-core/src/service/anyo-firestore.service';
import IAnyoListener from '../models/IAnyoListener';
import { ListenerService } from './listener.service';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { ChatV2Sessions } from '../models/chatV2Sessions';
import { ToastService } from '../../../../app-core/src/app/services/toastr.service';
import { ChatUserTypes, ChatV2Messages } from '../models/chatV2Messages';
import {
  ChatV2NewRequest,
  RejectedListeners,
} from '../models/chatV2NewRequest';
import {
  NetworkUtilsService,
  URL_DICT,
} from '../../../../app-core/src/service/network-utils.service';
import { Firestore } from '@angular/fire/firestore';
import {
  Database,
  getDatabase,
  onDisconnect,
  onValue,
  ref,
  serverTimestamp,
  set,
} from '@angular/fire/database';
import { PendingChatSummaryResponse } from '../models/pendingChatSummaryResponse';
import { ChatSummaryRequest } from '../components/session-feedback/session-feedback.component';
import { ChatSummaryListResponse } from '../models/chhatSummaryListResponse';
import * as moment from 'moment/moment';
import { Timestamp } from 'firebase/firestore';
import { list } from 'rxfire/database';

@Injectable({
  providedIn: 'root',
})
export class ChatV2Service {
  firestore: Firestore = inject(Firestore);
  database: Database = inject(Database);

  constructor(
    private firestoreDb: AnyoFirestoreService,
    private listenerService: ListenerService,
    private toastService: ToastService,
    private apiService: NetworkUtilsService,
    private authService: AuthService,
  ) {
    this.authService.currentAuthStatus.subscribe((user) => {
      if (user) {
        const isOfflineForDatabase = {
          state: 'offline',
          last_changed: serverTimestamp(),
        };
        const isOnlineForDatabase = {
          state: 'online',
          last_changed: serverTimestamp(),
        };

        const userRef = ref(this.database, `users/${user.userId}`);
        const presenceRef = ref(getDatabase(), '.info/connected');
        onValue(presenceRef, async (snapshot) => {
          if (snapshot.val() == false) {
            return;
          }
          await onDisconnect(userRef)
            .set(isOfflineForDatabase)
            .then(async () => {
              await set(userRef, isOnlineForDatabase);
            });
        });
        listenerService.getListener().subscribe((listener) => {
          this.listener = listener;
          this.listenerSub.next(this.listener);
        });
      }
    });
  }

  listener: IAnyoListener | null = null;
  listenerSub = new BehaviorSubject(this.listener);

  async setListenerTyping(sessionId: string, value: boolean) {
    console.log(sessionId, value);
    await this.firestoreDb.db
      .collection(FirestoreCollection.CHAT_SESSIONS)
      .doc(sessionId)
      .update({
        listenerTyping: value,
      });
  }

  async setListenerToOnline() {
    const listenerPath = this.firestoreDb.constructFirestoreDbPath(
      FirestoreCollection.LISTENERS,
      this.listener!._id,
    );
    this.listener!.listerAvailability = {
      listenerOnline: true,
      availableSlots: 3,
    };
    await this.firestoreDb.updateDocument(listenerPath, this.listener);
  }

  async setExpertToOnline() {
    const listenerPath = this.firestoreDb.constructFirestoreDbPath(
      FirestoreCollection.LISTENERS,
      this.listener!._id,
    );
    this.listener!.listerAvailability = {
      expertOnline: true,
    };
    await this.firestoreDb.updateDocument(listenerPath, this.listener);
  }

  async setListenerToOffLine() {
    const listenerPath = this.firestoreDb.constructFirestoreDbPath(
      FirestoreCollection.LISTENERS,
      this.listener?._id!,
    );
    this.listener!.listerAvailability = {
      listenerOnline: false,
    };
    await this.firestoreDb.updateDocument(listenerPath, this.listener);
  }

  async setExpertToOffLine(date?: Date) {
    const listenerPath = this.firestoreDb.constructFirestoreDbPath(
      FirestoreCollection.LISTENERS,
      this.listener?._id!,
    );
    this.listener!.listerAvailability = {
      expertOnline: false,
      expertNextAvailable: date,
    };
    await this.firestoreDb.updateDocument(listenerPath, this.listener);
  }

  listenerStatusObserver() {
    const listenerPath = this.firestoreDb.constructFirestoreDbPath(
      FirestoreCollection.LISTENERS,
      this.listener!._id,
    );

    return this.firestoreDb.attachListener<IAnyoListener>(listenerPath);
  }

  chatSessionStatusObserver() {
    return this.firestoreDb.db
      .collection(FirestoreCollection.CHAT_SESSIONS, (ref) =>
        ref
          .where('live', '==', true)
          .where('listenerIds', 'array-contains', this.listener!._id),
      )
      .snapshotChanges();
  }

  fetchPendingFeedbackSessions() {
    return this.apiService.get<PendingChatSummaryResponse[]>(
      URL_DICT.getPendingChatSummary,
    );
  }

  chatSessionObserver(sessionId: string) {
    return this.firestoreDb.db
      .collection(FirestoreCollection.CHAT_SESSIONS)
      .doc(sessionId)
      .snapshotChanges();
  }

  newChatObserver() {
    return this.firestoreDb.db
      .collection(FirestoreCollection.NEW_CHAT_REQUESTS, (ref) =>
        ref
          .where('listenerRequest.listenerId', '==', this.listener!._id)
          .where('requestExpiry', '>=', new Date())
          .where('requestExpired', '==', false)
          .where('chatAccepted', '==', false),
      )
      .snapshotChanges();
  }

  getMessages(chatSessionId: string) {
    return this.firestoreDb.db
      .collection(FirestoreCollection.CHAT_SESSIONS)
      .doc(chatSessionId)
      .collection(FirestoreSubCollection.MESSAGES)
      .get();
  }

  async sendNewMessage(message: string, sessionId: string, listenerId: string) {
    const chatSessionSnap = await lastValueFrom(
      this.firestoreDb.db
        .collection(FirestoreCollection.CHAT_SESSIONS)
        .doc(sessionId)
        .get(),
    );
    const chatSession = chatSessionSnap.data() as ChatV2Sessions;
    if (!chatSession.live) {
      this.toastService.showError('Chat session not live');
      return;
    }
    const firestoreMessage: ChatV2Messages = {
      message: message,
      senderId: listenerId,
      senderType: ChatUserTypes.Listener,
      timestamp: Timestamp.now(),
    };
    await this.firestoreDb.db
      .collection(FirestoreCollection.CHAT_SESSIONS)
      .doc(sessionId)
      .collection(FirestoreSubCollection.MESSAGES)
      .add(firestoreMessage);
  }

  async checkActiveChatsAndRequests() {
    const liveChats = await lastValueFrom(
      this.firestoreDb.db
        .collection(FirestoreCollection.CHAT_SESSIONS, (ref) =>
          ref
            .where('live', '==', true)
            .where('listenerIds', 'array-contains', this.listener!._id),
        )
        .get(),
    );
    if (!liveChats.empty) {
      return true;
    }
    const liveRequests = await lastValueFrom(
      this.firestoreDb.db
        .collection(FirestoreCollection.NEW_CHAT_REQUESTS, (ref) =>
          ref
            .where('listenerRequest.listenerId', '==', this.listener!._id)
            .where('expertChat', '==', false)
            .where('requestExpiry', '>=', new Date())
            .where('chatAccepted', '==', false),
        )
        .get(),
    );
    if (!liveRequests.empty) {
      return true;
    }
    return false;
  }

  chatSessionMessageObserver(sessionId: string) {
    return this.firestoreDb.db
      .collection(FirestoreCollection.CHAT_SESSIONS)
      .doc(sessionId)
      .collection(FirestoreSubCollection.MESSAGES, (ref) =>
        ref.orderBy('timestamp', 'asc'),
      )
      .snapshotChanges(['added']);
  }

  async acceptChat(newChatRequestId: string) {
    debugger;
    try {
      const newChatRequest = (
        await lastValueFrom(
          this.firestoreDb.db
            .collection(FirestoreCollection.NEW_CHAT_REQUESTS)
            .doc(newChatRequestId)
            .get(),
        )
      ).data() as unknown as ChatV2NewRequest;
      const newSessionId = this.firestoreDb.db
        .collection(FirestoreCollection.CHAT_SESSIONS)
        .doc().ref.id;

      const listener = {
        avatarName: this.listener!.avtarName,
        avatarImage: this.listener!.avtarImage,
        chatMinutes: this.listener!.totalMinutes,
        description: this.listener!.about,
        email: this.listener!.email,
        listenerId: this.listener!._id,
        name: this.listener!.name,
        numberChats: this.listener!.totalChats,
        rating: this.listener!.rating,
        speciality: this.listener!.specializationReadable,
      };
      const chatSession = {
        createdAt: new Date(),
        listenerIds: [this.listener!._id],
        listeners: [listener],
        live: true,
        startTime: new Date(),
        userIds: [newChatRequest.userId],
        userConcerns: newChatRequest.specialization,
        circleId: newChatRequest.circleId || '',
        expertId: newChatRequest.expertId || '',
        expertChat: newChatRequest.expertChat || false,
        expertName: newChatRequest.expertName || '',
        circleName: newChatRequest.circleName || '',
        circleColor: newChatRequest.circleColor || '',
        chatRequestId: newChatRequestId,
        users: [
          {
            gender: newChatRequest.userGender || '',
            partnerId: newChatRequest.partnerId || '',
            email: newChatRequest.userEmail,
            name: newChatRequest.userName,
            userId: newChatRequest.userId,
          },
        ],
      };
      await this.firestoreDb.db
        .collection(FirestoreCollection.CHAT_SESSIONS)
        .doc(newSessionId)
        .set(chatSession);
      await lastValueFrom(
        this.apiService.post(URL_DICT.acceptChat, {
          newSessionId: newSessionId,
          chatRequestId: newChatRequestId,
          listenerId: this.listener!._id,
        }),
      );
      await this.firestoreDb.db
        .collection(FirestoreCollection.NEW_CHAT_REQUESTS)
        .doc(newChatRequestId)
        .update({
          chatAccepted: true,
          chatAcceptedTime: new Date(),
          listenerDetails: {
            listenerId: this.listener!._id,
            email: this.listener!.email,
            name: this.listener!.name,
          },
        });
      return newSessionId;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  async rejectChat(requestId: string, rejectReason: string) {
    const chatRequestSnap = await lastValueFrom(
      this.firestoreDb.db
        .collection(FirestoreCollection.NEW_CHAT_REQUESTS)
        .doc(requestId)
        .get(),
    );
    const chatRequest = chatRequestSnap.data() as ChatV2NewRequest;
    if (!chatRequest.rejectedListeners) {
      chatRequest.rejectedListeners = [];
    }
    const rejected: RejectedListeners = {
      email: this.listener?.email!,
      listenerId: this.listener?._id!,
      rejectReason: rejectReason,
      rejectTime: new Date(),
    };
    chatRequest.rejectedListeners.push(rejected);
    await this.firestoreDb.db
      .collection(FirestoreCollection.NEW_CHAT_REQUESTS)
      .doc(requestId)
      .update({
        listenerRequest: {},
        rejectedListeners: chatRequest.rejectedListeners,
      });
    await this.firestoreDb.db
      .collection(FirestoreCollection.REJECTED_REQUESTS)
      .add({
        ...rejected,
        requestId: requestId,
      });
  }

  submitChatFeedback(request: ChatSummaryRequest) {
    return this.apiService.post(URL_DICT.postChatSummary, request);
  }

  async endChat(sessionId: string, rating: string, feedback: string) {
    await this.firestoreDb.db
      .collection(FirestoreCollection.CHAT_SESSIONS)
      .doc(sessionId)
      .update({
        listenerRatingForUser: rating,
        listenerFeedbackForUser: feedback,
        live: false,
        endTime: new Date(),
        listenerEndTime: new Date(),
        endedById: this.listener!._id,
        endedBy: 'Listener',
      });
  }

  fetchChatSummary(userId: string) {
    return this.apiService.get<ChatSummaryListResponse[]>(
      URL_DICT.getChatSummary + '?userId=' + userId,
    );
  }

  async updateFCMToken(token: string, userId: string) {
    const doc = this.firestoreDb.db
      .collection(FirestoreCollection.FCM_TOKEN)
      .doc(userId)
      .collection('web');
    const existingToken = await doc.ref.where('token', '==', token).get();
    if (!existingToken.empty) {
      return;
    }
    await doc.add({
      createdAt: moment().toDate(),
      token: token,
    });
  }
}

import { Component } from '@angular/core';
import { Menu } from 'projects/app-core/src/app/models/menu';
import { AuthService } from 'projects/app-core/src/auth/auth.service';
import { Roles } from 'projects/app-core/src/auth/models/roles';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent {
  user: any;
  menu: Menu[] = [
    {
      name: 'Home',
      icon: '../../../assets/icons/dashboard.svg',
    },
    {
      name: 'Chat',
      icon: '../../../assets/icons/listener.svg',
      subMenu: [
        {
          name: 'Chat with User',
          link: '/chat',
        },
      ],
    },
  ];

  constructor(private auth: AuthService) {}

  ngOnInit() {
    this.auth.currentAuthStatus.subscribe((user) => {
      if (user) {
        const allowedRoles = [
          Roles.CirclesModerator,
          Roles.Therapist,
          Roles.CircleManager,
        ];
        if (user.customClaims?.roles.includes(Roles.TalksManager)) {
          this.menu.push({
            name: 'Anyo Talks',
            icon: '../../../assets/icons/listener.svg',
            subMenu: [
              {
                name: 'Talks',
                link: 'talks/',
              },
            ],
          });
        }
        if (
          user.customClaims?.roles.some((role) => allowedRoles.includes(role))
        ) {
          this.menu.push({
            name: 'Posts',
            icon: 'assets/icons/challenge.svg',
            subMenu: [
              {
                name: 'Manage Posts',
                link: '/posts',
              },
            ],
          });
        }
        if (
          user.customClaims?.roles.includes(Roles.Therapist) ||
          user.roles.includes(Roles.Therapist)
        ) {
          this.menu.push({
            name: 'Appointments',
            icon: 'assets/icons/therapist.svg',
            subMenu: [
              {
                name: 'Manage Sessions',
                link: '/sessions/therapist',
              },
            ],
          });
        }
      }
    });
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListenerLayoutComponent } from './listener-layout/listener-layout.component';
import { ListenerLoginComponent } from './listener-login/listener-login.component';
import { ListenerSetupComponent } from './listener-setup/listener-setup.component';
import { ListenerForgotPasswordComponent } from './listener-forgot-password/listener-forgot-password.component';

const routes: Routes = [
  {
    path: '',
    component: ListenerLayoutComponent,
    children: [
      {
        path: '',
        component: ListenerLoginComponent,
      },
      {
        path: 'listener-login',
        component: ListenerLoginComponent,
      },
      {
        path: 'listener-login/:this.email/:this.password',
        component: ListenerLoginComponent,
      },
      {
        path: 'forgot-password',
        component: ListenerSetupComponent,
      },
      {
        path: 'otp-verify',
        component: ListenerForgotPasswordComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ListenerAuthRoutingModule {}

import { Component } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { ContentService } from '../../../services/content.service';
import { ToastService } from 'projects/app-core/src/app/services/toastr.service';
import {
  AnyoS3Buckets,
  FileUtilsService,
} from '../../../../../../app-core/src/service/fileService';
import { ICategory } from '../../../../../../app-core/src/models/ICategory';
import { MasterdataService } from '../../../services/masterdata.service';
import { AuthService } from '../../../../../../app-core/src/auth/auth.service';
import { MasterDataResponse } from '../../../models/masterDataResponse';
import { IAnyoError } from '../../../../../../app-core/src/models/errorModal';
import { lastValueFrom } from 'rxjs';
import { NewContentRequest } from '../../../models/newContentRequest';
import { IContent, IModule, RecipeDetails } from '../../../models/IContent';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { MediaType } from 'projects/app-core/src/app/posts/model/createPostRequestAdmin';
import { UtilsService } from 'projects/app-core/src/service/utils.service';
import { UpdateContentRequest } from '../view/view.component';
import { CirclesService } from '../../../services/circles.service';
import { CircleTopics } from '../../../models/circles/AnyoCircles';

interface CategorySelectOptions {
  label: string;
  value: string;
}

@Component({
  selector: 'app-add-recipe',
  templateUrl: './add-recipe.component.html',
  styleUrl: './add-recipe.component.scss',
})
export class AddRecipeComponent {
  recipeGenreId = '671cc6f28bfc939595824e2f';
  recipeDescription = '';
  timeTaken = 0;
  images: string[] = [];
  oldImagesUrl: string[] = [];
  imagesFile: File[] = [];
  mediaFiles: File[] = [];
  videoFile!: File;
  nutritionalInfo: { key: string; value: string }[] = [];
  ingredients: { name: string; color: string; url?: string }[] = [];
  directions: { stepNo: number; stepDescription: string; color: string }[] = [];
  facts: { fact: string; color: string }[] = [];
  author = '';
  videoUrlString = '';
  isVisible = false;
  editModeCheck = false;
  userInterestTopics: CircleTopics[] = [];
  selectedUserInterests: string[] = [];

  editMode() {
    this.editModeCheck = !this.editModeCheck;
  }

  addIngredientField() {
    this.ingredients.push({
      name: '',
      color: this.utilsService.getRandomColor(),
    });
  }

  addFactsField() {
    this.facts.push({
      fact: '',
      color: this.utilsService.getRandomColor(),
    });
  }

  removeFactField(i: number) {
    this.facts.splice(i, 1);
  }

  removeIngredientField(i: number) {
    this.ingredients.splice(i, 1);
  }

  deleteSteps(i: number) {
    this.directions.splice(i, 1);
    for (let i = 0; i < this.directions.length; i++) {
      this.directions[i].stepNo = i + 1;
    }
  }

  addSteps() {
    this.directions.push({
      stepNo: this.directions.length + 1,
      stepDescription: '',
      color: this.utilsService.getRandomColor(),
    });
  }
  addNutriInfo() {
    this.nutritionalInfo.push({
      key: '',
      value: '',
    });
  }
  uploadImages($event: any) {
    const files = ($event.target as HTMLInputElement).files;

    if (files) {
      const newFiles = Array.from(files).map((file: File) => {
        const uuid = uuidv4();
        const uuidWithoutHyphens = uuid.replace(/-/g, '');
        return new File([file], uuidWithoutHyphens, { type: file.type });
      });
      this.mediaFiles = this.mediaFiles.concat(newFiles);
      this.readMedia(newFiles);
    }
  }

  uploadMedia($event: any) {
    const file = $event.target.files[0];
    if (file) {
      this.videoFile = file;
      this.readMedia([file]);
    }
  }

  getContentType(contentType: string) {
    switch (contentType) {
      case 'image/jpeg':
        return MediaType.IMAGE;
      case 'image/png':
        return MediaType.IMAGE;
      case 'image/jpg':
        return MediaType.IMAGE;
      case 'video/mp4':
        return MediaType.VIDEO;
      default:
        throw 'invalid type';
    }
  }

  readMedia(files: File[]) {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();

      reader.onload = (e: any) => {
        const mediaType = this.getContentType(file.type);
        if (mediaType === MediaType.IMAGE) {
          this.images.push(e.target.result);
          this.imagesFile.push(file);
        } else {
          this.videoFile = file;
          this.videoUrlString = e.target.result;
        }
      };

      reader.readAsDataURL(file);
    }
  }

  deleteImage(index: number) {
    this.images.splice(index, 1);
    this.mediaFiles.splice(index, 1);
    this.oldImagesUrl.splice(index, 1);
  }

  modules: IModule[] = [];
  contentSpinning: boolean = false;
  newContentId: string | undefined;
  categories: CategorySelectOptions[] = [];
  practiceName: string = '';
  masterData: MasterDataResponse | undefined;
  selectedCategory: ICategory | undefined;
  selectedCategoryString: string | undefined;
  contentId: string | undefined;
  updatePage = false;

  constructor(
    private auth: AuthService,
    private contentService: ContentService,
    private toastService: ToastService,
    private masterDataService: MasterdataService,
    private fileService: FileUtilsService,
    private router: Router,
    private utilsService: UtilsService,
    private activatedRoute: ActivatedRoute,
    private circleService: CirclesService,
  ) { }

  ngOnInit() {
    this.contentSpinning = true;
    this.auth.currentAuthStatus.subscribe(async (user) => {
      if (user) {
        const id = this.activatedRoute.snapshot.params['id'];
        if (id) {
          this.contentId = id;
          this.getContent(id);
          this.updatePage = true;
        } else {
          this.contentService.getNewContentId().subscribe({
            next: (response) => {
              this.newContentId = response.data;
            },
            error: (error) => {
              const errorBody = error.error as IAnyoError;
              this.toastService.showAnyoErrorToast(errorBody.description);
            },
          });
        }
        this.userInterestTopics =
          await this.circleService.getUserInterestsMeta();
        this.masterDataService.masterDataList().subscribe((response) => {
          const category = response.categories.filter(
            (data) => data.genreId === this.recipeGenreId,
          );
          if (category) {
            this.categories = category.map((cat) => {
              return {
                label: cat.category,
                value: cat._id,
              };
            });
          }

          this.masterData = response;
        });
        this.contentSpinning = false;
      }
    });
  }

  validateNumber(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    inputElement.value = inputElement.value.replace(/[^0-9]/g, '');
  }

  async saveImages() {
    const imagesUploadedUrl = [];

    if (this.imagesFile.length) {
      for (let i = 0; i < this.images.length; i++) {
        const url = await this.handleFileUpload(
          this.imagesFile[i],
          `module_thumbnail_recipe_${i + 1}`,
          `thumbnail_recipe ${i + 1}`,
        );
        imagesUploadedUrl.push(url);
      }
    }

    return imagesUploadedUrl;
  }

  async saveContent() {
    try {
      this.contentSpinning = true;

      if (!this.practiceName || !this.recipeDescription) {
        this.toastService.showError('Please fill in the recipe name and description.');
        return;
      }

      const imagesUrls = await this.saveImages()
      const recipeDetails = this.generateRecipeDetails(imagesUrls)


      if (this.videoFile) {
        const videoUrl = await this.handleFileUpload(
          this.videoFile,
          `module_media_recipe`,
          `media_recipe`,
        );
        recipeDetails.videoUrl = videoUrl;
      }

      const newContentRequest: NewContentRequest = {
        categoryId: this.selectedCategoryString!,
        description: this.recipeDescription!,
        instructor: [],
        newContentId: this.newContentId!,
        practiceName: this.practiceName,
        module: [],
        contentThumbnail: '',
        contentBanner: '',
        visible: this.isVisible,
        userInterest: this.selectedUserInterests,
      };
      if (imagesUrls.length) {
        newContentRequest.contentBanner = imagesUrls[0]
        newContentRequest.contentThumbnail = imagesUrls[0]

      }
      const modules: IModule[] = this.generateModule(imagesUrls, recipeDetails)
      newContentRequest.module = modules;
      this.contentService.createContent(newContentRequest).subscribe({
        next: (value) => {
          this.toastService.showSuccess('Content added successfully');
          this.contentSpinning = false;
          this.router.navigate(['/content']);
        },
        error: (err) => {
          const errorBody = err.error as IAnyoError;
          this.toastService.showAnyoErrorToast(errorBody.description);
          this.contentSpinning = false;
        },
      });
    } catch (e) {
      this.toastService.showError(e + '');
      this.contentSpinning = false;
    }
  }

  generateRecipeDetails(imageUrls: string[]) {
    const nutritionInfo: Record<string, string> = this.nutritionalInfo.reduce((acc, { key, value }) => {
      acc[key.toLocaleLowerCase()] = value
      return acc
    }, {} as Record<string, string>)

    const recipeDetails: RecipeDetails = {
      name: this.practiceName,
      timeTaken: this.timeTaken,
      description: this.recipeDescription,
      nutritionalInfo: nutritionInfo || [],
      ingredients: this.ingredients.map((data) => {
        return {
          name: data.name,
          url: data.url
        }
      }) || [],
      steps: this.directions.map((data) => {
        return {
          stepDescription: data.stepDescription,
          stepNo: data.stepNo
        }
      }) || [],
      facts: this.facts.map((data) => {
        return {
          fact: data.fact
        }
      }) || [],
      imagesUrl: imageUrls.concat(this.oldImagesUrl),
      videoUrl: this.videoUrlString,
      author: this.author,
    };
    return recipeDetails;
  }

  generateModule(imagesUploadedUrl: string[], recipeDetails: RecipeDetails) {
    const modules: IModule[] = [];
    const module: IModule = {
      estimatedTime: this.timeTaken.toString(),
      isMandatory: true,
      moduleId: '1',
      moduleName: this.practiceName,
      moduleDescription: this.recipeDescription,
      thumbnail: imagesUploadedUrl[0] || '',
      type: MediaType.RECIPE,
      uid: uuidv4(),
      url: imagesUploadedUrl[0] || '',
      banner: imagesUploadedUrl[0] || '',
      recipeDetails: recipeDetails,
    };
    modules.push(module);
    return modules;
  }

  async updateContent() {
    try {
      this.contentSpinning = true;


      if (!this.practiceName || !this.recipeDescription) {
        this.toastService.showError('Please fill in the recipe name and description.');
        return;
      }


      const updateContentRequest: UpdateContentRequest = {
        categoryId: this.selectedCategoryString!,
        description: this.recipeDescription!,
        instructor: [],
        practiceName: this.practiceName,
        module: [],
        contentThumbnail: this.oldImagesUrl[0] || '',
        contentBanner: this.oldImagesUrl[0] || '',
        visible: this.isVisible,
        userInterest: this.selectedUserInterests,
      };

      const imagesUrls = await this.saveImages()

      const recipeDetails = this.generateRecipeDetails(imagesUrls)

      if (this.videoFile) {
        const videoUrl = await this.handleFileUpload(
          this.videoFile,
          `module_media_recipe`,
          `media_recipe`,
        );
        recipeDetails.videoUrl = videoUrl;
      }
      const modules = this.generateModule(imagesUrls, recipeDetails);
      updateContentRequest.module = modules;

      if (imagesUrls.length) {
        updateContentRequest.contentBanner = imagesUrls[0]
        updateContentRequest.contentThumbnail = imagesUrls[0]

      }

      await lastValueFrom(
        this.contentService.updateContent(
          this.contentId!,
          updateContentRequest,
        ),
      ).then(() => {
        this.toastService.showSuccess('Content updated successfully');
        this.contentSpinning = false;
        this.router.navigate(['/content']);
      });
    } catch (e) {
      this.toastService.showError(e + '');
      this.contentSpinning = false;
    }
  }

  async handleFileUpload(
    file: File,
    filePathString: string,
    toastrString: string,
  ) {
    try {
      const extension = file.name.split('.').pop();
      const timestamp = moment().format('x');
      const filePath = `content/${this.newContentId}/${filePathString}_${timestamp}.${extension}`;
      const uploadUrl = await lastValueFrom(
        this.fileService.generateUploadUrl(
          AnyoS3Buckets.CDN_BUCKET,
          filePath,
          file.type,
        ),
      );
      this.toastService.showSuccess(`Uploading ${toastrString}`);
      await lastValueFrom(this.fileService.uploadFile(file, uploadUrl.url));
      this.toastService.showSuccess(
        `Uploading module ${toastrString} completed`,
      );
      return `https://cdn.anyo.app/${filePath}`;
    } catch (e) {
      console.log(e);
      this.toastService.showError(e + '');
      throw e;
    }
  }

  deleteNutriInfo(index: number | undefined) {
    if (index != undefined) {
      this.nutritionalInfo.splice(index, 1);
    }
  }

  categoryChangeFn($event: string) {
    if ($event) {
      const category = this.masterData!.categories!.filter(
        (value) => value._id == $event,
      )[0];
      this.selectedCategory = category;
    }
  }

  getContent(id: string) {
    this.contentService.getContent(id).subscribe({
      next: (value: IContent) => {
        this.selectedUserInterests = value.userInterest;
        this.isVisible = value.visible;
        this.videoUrlString = value.module[0].recipeDetails?.videoUrl || '';
        value.module[0].recipeDetails?.imagesUrl!.forEach((data) => {
          this.images.push(data)
          this.oldImagesUrl.push(data)
        })
        this.practiceName = value.practiceName;
        this.timeTaken = value.module[0].recipeDetails?.timeTaken || 0;
        this.recipeDescription =
          value.module[0].recipeDetails?.description || '';
        const category = value.category;
        this.selectedCategoryString = category?._id;
        this.author = value.module[0].recipeDetails?.author || '';
        this.facts =
          value.module[0].recipeDetails?.facts.map((data) => {
            return {
              fact: data.fact,
              color: this.utilsService.getRandomColor(),
            };
          }) || [];
        this.directions =
          value.module[0].recipeDetails?.steps.map((data) => {
            return {
              stepNo: data.stepNo,
              stepDescription: data.stepDescription,
              color: this.utilsService.getRandomColor(),
            };
          }) || [];
        this.ingredients =
          value.module[0].recipeDetails?.ingredients.map((data) => {
            return {
              name: data.name,
              url: data.url,
              color: this.utilsService.getRandomColor(),
            };
          }) || [];
        Object.entries(
          value.module[0].recipeDetails?.nutritionalInfo || '',
        ).forEach(([key, value]) => {
          this.nutritionalInfo.push({
            key: key,
            value: value,
          });
        });
      },
      error: (err) => {
        const errorBody = err.error as IAnyoError;
        this.toastService.showAnyoErrorToast(errorBody.description);
      },
    });
  }
}

import { Injectable } from '@angular/core';
import { NetworkUtilsService, URL_DICT } from 'projects/app-core/src/service/network-utils.service';
import { ExpertsGridRequest } from '../models/expertsGridRequest';
import { ExpertGridResponse } from '../models/expertGridResponse';
import { CreateExpertTherapistListenerRequest } from '../models/createExpertTherapistListener';

@Injectable({
  providedIn: 'root'
})
export class ExpertsService {

  constructor(private api: NetworkUtilsService) { }

  listExperts(request: ExpertsGridRequest){
    return this.api.post<ExpertGridResponse>(URL_DICT.adminExpertsGrid,request)
  }

  createExpert(req:CreateExpertTherapistListenerRequest){
    return this.api.post(URL_DICT.adminExpertsTherapistListenerCreate,req)
  }

  editExpert(userId: string,req:CreateExpertTherapistListenerRequest){
   return this.api.post<CreateExpertTherapistListenerRequest>(URL_DICT.adminExpertsGet + userId,req)
  }

  getExpertTherapistListener (userId: string){
    return this.api.get<CreateExpertTherapistListenerRequest>(URL_DICT.adminExpertsGet + userId)
  }
}

<div class="flex h-screen">
  <div class="flex-1 p-10 background">
    <p class="text-5xl header-secondary font-semibold line-height">
      Start your
    </p>
    <p class="text-5xl font-semibold line-height mb-2">
      <span class="header-primary">Wellness journey</span
      ><span class="header-secondary"> with us!</span>
    </p>
    <p class="flex text-black font-styles text-justify">
      Navigating stress and unlocking energy to reach peak potential. Partnering
      for a stronger, more resilient community. Let's talk you....
    </p>
    <div class="absolute bottom-0 left-0 w-3/5 fade">
      <img
        src="../../../assets/images/partner-login-background.png"
        class="w-full h-full object-cover"
      />
    </div>
  </div>

  <div class="flex-1 bg-white">
    <div class="flex-1 flex-start">
      <div class="flex justify-center m-12">
        <img src="../../../assets/images/logo.svg" />
      </div>
      <router-outlet></router-outlet>
      <div class="flex flex-col justify-end items-center">
        <p class="absolute bottom-0 text-black m-4">
          Copyright Ⓒ Anyo.app. All Rights Reserved
        </p>
      </div>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import posthog from 'posthog-js';
import { IAnyoUser } from '../../../../app-core/src/auth/models/IAnyoUser';
import { AuthService } from '../../../../app-core/src/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class PostHogService {
  user: IAnyoUser | undefined = undefined;
  constructor(auth: AuthService) {
    auth.currentAuthStatus.subscribe((user) => {
      this.user = user;
    });
    posthog.init('phc_rKeOKmDQwCCCT0uCtESWFfxCodtbgGbR100PrrXG4oP', {
      api_host: 'https://app.posthog.com',
    });
  }
  setIdentity(email: string, name: string, userId: string) {
    posthog.identify(userId, { email: email, name: name, role: 'Listener' });
  }
  postEvents(event: string, eventData?: Record<string, string | number>) {
    if (!this.user) {
      return;
    }
    posthog.capture(event, eventData);
  }
}

import { Component } from '@angular/core';
import { AuthService } from 'projects/app-core/src/auth/auth.service';
import { IAnyoUser } from 'projects/app-core/src/auth/models/IAnyoUser';
import { TherapistExpertLevel } from 'projects/app-core/src/models/TherapistExpertLevel';
import { CircleTopics } from '../../../models/circles/AnyoCircles';
import { CirclesService } from '../../../services/circles.service';
import { MasterdataService } from '../../../services/masterdata.service';
import { ToastService } from 'projects/app-core/src/app/services/toastr.service';
import { ExpertsService } from '../../../services/experts.service';
import {
  AnyoS3Buckets,
  FileUtilsService,
} from 'projects/app-core/src/service/fileService';
import { IAnyoError } from 'projects/app-core/src/models/errorModal';
import {
  AnyoCurdComponent,
  CRUD,
} from 'projects/app-core/src/app/models/AnyoCrudComponent';
import { lastValueFrom } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { CreateExpertTherapistListenerRequest } from '../../../models/createExpertTherapistListener';
import { Status } from '../../../stores/types';
import { ActivatedRoute, Router } from '@angular/router';

interface ExpertLevelArray {
  verticalId: string;
  verticalName: string;
  concernsList: {
    label: string;
    value: string;
    disabled: boolean;
  }[];
  concerns: {
    concernsId: string;
    concernName: string;
    expertLevelId: string;
    expertLevel: string;
  }[];
}

interface DropdownOptions {
  value: string;
  data: string;
}

@Component({
  selector: 'app-crud-experts',
  templateUrl: './crud-experts.component.html',
  styleUrl: './crud-experts.component.scss',
})
export class CrudExpertsComponent extends AnyoCurdComponent {
  crudType: CRUD = CRUD.CREATE;
  archived = false;
  editPage = false;
  expertLevels: string[] = [];
  therapistExpertLevels: TherapistExpertLevel[] = [];
  selectedTherapistLevel: string = '';
  idealPrice = 0;
  rating = 0;
  userInterestTopics: CircleTopics[] = [];
  selectedUserInterests: string[] = [];
  tagLine = '';
  expertiseLevelArray: ExpertLevelArray[] = [];
  firstName = '';
  lastName = '';
  email = '';
  phoneNo = '';
  about = '';
  therapistImageFile!: File;
  videoBioFile!: File;
  videoSrc = '';
  profilePic = '';
  isExpert = false;
  isTherapist = false;
  isGoAnyoLive = false;
  isListener = false;
  title = '';
  yearOfExperiance = 0;
  selectedLanguage: string[] = [];
  userId = '';

  verticalIds: string[] = [];
  concernIds: string[] = [];
  concernExpertLevelMap: Record<
    string,
    {
      level: string;
      id: string;
    }
  > = {};

  maxPricing = 0;
  minPricing = 0;
  therapistSlug ="";

  constructor(
    protected override auth: AuthService,
    private circleService: CirclesService,
    private masterdataService: MasterdataService,
    private toastrService: ToastService,
    private experts: ExpertsService,
    private fileService: FileUtilsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {
    super(auth);
    this.addVertical();
  }

  override async getData(user?: IAnyoUser | undefined) {
    if (user) {
      if (this.crudType === CRUD.CREATE) {
        this.pageLoading = true;
        this.loadMasterData();
      } else {
        const value = this.activatedRoute.snapshot.params['id'];
        this.userId = value;
        this.pageLoading = true;
        this.loadMasterData();
      }
    }
  }

  changeActiveListener($event: boolean) {
    this.isListener = true;
  }

  uploadTherapistImage($event: any) {
    const file = $event.target.files[0];
    if (file) {
      this.therapistImageFile = file;
      this.readMedia(this.therapistImageFile);
    }
  }

  uploadVideoImage($event: any) {
    const file = $event.target.files[0];
    if (file) {
      this.videoBioFile = file;
      this.previewVideo(this.videoBioFile);
    }
  }

  previewVideo(file: File): void {
    const reader = new FileReader();
    reader.onload = (event: any) => {
      this.videoSrc = event.target.result;
    };
    reader.readAsDataURL(file);
  }

  readMedia(file: File) {
    const reader = new FileReader();

    reader.onload = (e: any) => {
      this.profilePic = e.target.result;
    };

    reader.readAsDataURL(file);
  }

  avatarCard = false;
  avatar = '';
  thumbnail = '';
  avatarName = '';

  show() {
    this.avatarCard = !this.avatarCard;
    const commonCard = document.getElementById('card');
    const choose = document.getElementById('chooseAvatar');
    if (choose && commonCard) {
      if (this.avatarCard) {
        const elementPosition = choose.getBoundingClientRect();
        commonCard.style.display = 'flex';
        commonCard.style.top = `${elementPosition.bottom}px`;
        commonCard.style.left = `${elementPosition.left}px`;
      } else {
        commonCard.style.display = 'none';
      }
    }
  }

  uploadAvatar(id: string, label: string) {
    const commonCard = document.getElementById('card');
    if (commonCard) commonCard.style.display = 'none';
    this.avatar = label;
    this.thumbnail = id;
  }

  addVertical() {
    this.expertiseLevelArray.push({
      verticalId: '',
      verticalName: '',
      concernsList: [],
      concerns: [
        {
          concernsId: '',
          concernName: '',
          expertLevelId: '',
          expertLevel: '',
        },
      ],
    });
  }
  deleteVertical(i: number) {
    this.expertiseLevelArray.splice(i, 1);
    const selectedVerticalsIds = this.expertiseLevelArray.map(
      (value) => value.verticalId,
    );
    this.verticalsMasterDataFiltered.forEach((value) => {
      value.disabled = selectedVerticalsIds.includes(value.value);
    });
  }
  addConcern(i: number) {
    this.expertiseLevelArray[i].concerns.push({
      concernsId: '',
      concernName: '',
      expertLevelId: '',
      expertLevel: '',
    });
  }
  deleteConcern(i: number, j: number) {
    this.expertiseLevelArray[i].concerns.splice(j, 1);
    const selectedConcernsIds = this.expertiseLevelArray[i].concerns.map(
      (value) => value.concernsId,
    );
    this.expertiseLevelArray[i].concernsList = this.concernsMasterData
      .filter((value) =>
        value.verticalId.includes(this.expertiseLevelArray[i].verticalId),
      )
      .map((value) => {
        return {
          value: value.value,
          label: value.label,
          disabled: selectedConcernsIds.includes(value.value),
        };
      });
  }
  verticalChange(i: number, $event: any) {
    this.expertiseLevelArray[i].verticalId = $event;
    const selectedVerticalsIds = this.expertiseLevelArray.map(
      (value) => value.verticalId,
    );
    this.verticalsMasterDataFiltered = this.verticalsMasterData.map((value) => {
      return {
        value: value.value,
        label: value.label,
        disabled: selectedVerticalsIds.includes(value.value),
      };
    });
    this.expertiseLevelArray[i].verticalName =
      this.verticalsMasterDataFiltered.find((value) => value.value == $event)
        ?.label || '';
    this.expertiseLevelArray[i].concernsList = this.concernsMasterData
      .filter((value) => value.verticalId.includes($event))
      .map((value) => {
        return {
          value: value.value,
          label: value.label,
          disabled: false,
        };
      });
  }
  concernChange(i: number, j: number, $event: any) {
    this.expertiseLevelArray[i].concerns[j].concernsId = $event;
    this.expertiseLevelArray[i].concerns[j].concernName =
      this.expertiseLevelArray[i].concernsList.find(
        (value) => value.value == $event,
      )?.label || '';
    const selectedConcernsIds = this.expertiseLevelArray[i].concerns.map(
      (value) => value.concernsId,
    );
    this.expertiseLevelArray[i].concernsList = this.concernsMasterData
      .filter((value) =>
        value.verticalId.includes(this.expertiseLevelArray[i].verticalId),
      )
      .map((value) => {
        return {
          value: value.value,
          label: value.label,
          disabled: selectedConcernsIds.includes(value.value),
        };
      });
  }
  expertLevelChange(i: number, j: number, $event: any) {
    this.expertiseLevelArray[i].concerns[j].expertLevelId = $event;
    this.expertiseLevelArray[i].concerns[j].expertLevel =
      this.expertLevelMasterData.find((value) => value.value == $event)
        ?.label || '';
  }
  verticalConcernMap: Record<
    string,
    {
      label: string;
      value: string;
    }[]
  > = {};
  expertLevelMasterData: {
    label: string;
    value: string;
  }[] = [];
  verticalsMasterData: {
    label: string;
    value: string;
  }[] = [];
  verticalsMasterDataFiltered: {
    label: string;
    value: string;
    disabled: boolean;
  }[] = [];
  concernsMasterData: {
    verticalId: string[];
    label: string;
    value: string;
  }[] = [];
  expertise: DropdownOptions[] = [];
  languages: DropdownOptions[] = [];
  validateExpertLevelArray() {
    let isValid = true;
    if (this.expertiseLevelArray.length == 0) {
      isValid = false;
    }
    this.expertiseLevelArray.forEach((value) => {
      if (!value.verticalId) {
        isValid = false;
      }
      value.concerns.forEach((concern) => {
        if (!concern.concernsId || !concern.expertLevelId) {
          isValid = false;
        }
      });
    });

    return isValid;
  }

  avatarImages: DropdownOptions[] = [];
  avatarNames: DropdownOptions[] = [];
  anyoTalks: DropdownOptions[] = [];
  selectedAnyoTalks: string[] = [];

  async loadMasterData() {
    this.userInterestTopics = await this.circleService.getUserInterestsMeta();
    this.masterdataService.masterDataList().subscribe({
      next: (masterdata) => {
        masterdata.anyoTalks.forEach((data) => {
          const dropdownOptionsanyoTalks: DropdownOptions = {
            data: data.name.toString(),
            value: data._id?.toString(),
          };
          this.anyoTalks.push(dropdownOptionsanyoTalks);
        });
        if (masterdata.languages) {
          masterdata.languages.forEach((data) => {
            const dropdownOptionsLanguages: DropdownOptions = {
              data: data.data.toString(),
              value: data.m_id?.toString() || '',
            };
            this.languages.push(dropdownOptionsLanguages);
          });
        }
        if (masterdata.areasOfExpertise) {
          masterdata.areasOfExpertise.forEach((data) => {
            const areasOfExpertise: DropdownOptions = {
              data: data.data,
              value: data.data,
            };
            this.expertise.push(areasOfExpertise);
          });
        }
        if (masterdata.therapyVerticals) {
          masterdata.therapyVerticals.forEach((data) => {
            const verticalsDropDownOption = {
              label: data.data,
              value: data.m_id,
              disabled: false,
            };
            this.verticalsMasterData.push(verticalsDropDownOption);
            this.verticalsMasterDataFiltered.push(verticalsDropDownOption);
          });
        }
        if (masterdata.therapyConcerns) {
          masterdata.therapyConcerns.forEach((data) => {
            const select2Data = {
              verticalId: data.verticalId,
              label: data.data,
              value: data.m_id,
            };
            this.concernsMasterData.push(select2Data);
          });
        }
        if (masterdata.therapistExpertLevels) {
          masterdata.therapistExpertLevels.forEach((data) => {
            const select2Data = {
              label: data.data,
              value: data.m_id,
            };
            this.expertLevelMasterData.push(select2Data);
          });
        }

        masterdata.avatarImages.forEach((data) => {
          if (data.isAvailable) {
            if (data.status == Status.Active) {
              const obj = {
                value: data.m_id!,
                data: data.data,
              };
              this.avatarImages.push(obj);
            }
          }
        });

        masterdata.avatarNames.forEach((data) => {
          if (data.isAvailable) {
            if (data.status == Status.Active) {
              const obj = {
                value: data.m_id!,
                data: data.data,
              };
              this.avatarNames.push(obj);
            }
          }
        });

        this.therapistExpertLevels = masterdata.therapistExpertLevels;
        this.expertLevels = this.therapistExpertLevels.map(
          (value) => value.data,
        );
        if (this.crudType === CRUD.CREATE) {
          this.pageLoading = false;
        } else {
          this.get(this.userId);
        }
      },
      error: (error) => {
        const errorBody = error.error as IAnyoError;
        this.toastrService.showAnyoErrorToast(errorBody.description);
        this.pageLoading = false;
      },
    });
  }

  validation() {
    if (
      !this.title ||
      !this.firstName ||
      !this.lastName ||
      !this.email ||
      !this.phoneNo
    ) {
      this.toastrService.showError('Please enter all fields in general');
      this.pageLoading = false;
      return true;
    }
    if (!this.profilePic) {
      this.toastrService.showError('Please upload profile image');
      this.pageLoading = false;
      return true;
    }
    if (this.isTherapist) {
      if (
        !this.validateExpertLevelArray() ||
        this.tagLine === '' ||
        this.rating <= 0 ||
        this.maxPricing <= 0 ||
        this.selectedLanguage.length <= 0 ||
        this.about === '' ||
        this.yearOfExperiance <= 0 ||
        this.selectedTherapistLevel === ''
      ) {
        this.toastrService.showError(
          'Please enter all fields in expert consultation',
        );
        this.pageLoading = false;
        return true;
      }
    }
    return false;
  }

  override async create() {
    this.pageLoading = true;
    if (this.validation()) {
      return;
    }
    this.verticalIds = this.expertiseLevelArray.map(
      (value) => value.verticalId,
    );
    this.concernIds = this.expertiseLevelArray
      .map((value) => value.concerns.map((concern) => concern.concernsId))
      .flat();
    this.concernExpertLevelMap = this.expertiseLevelArray.reduce(
      (
        acc: Record<
          string,
          {
            level: string;
            id: string;
          }
        >,
        value,
      ) => {
        value.concerns.forEach((concern) => {
          acc[concern.concernsId] = {
            id: concern.expertLevelId,
            level: concern.expertLevel,
          };
        });
        return acc;
      },
      {},
    );

    this.userInterestTopics = this.userInterestTopics
      .filter((value) => this.selectedUserInterests.includes(value.key))
      .map((value) => {
        return {
          key: value.key,
          value: value.value,
        };
      });
    const newExperts: CreateExpertTherapistListenerRequest = {
      slug: this.therapistSlug,
      archived: this.archived,
      anyotalksIds: this.selectedAnyoTalks,
      activeTherapist: this.isTherapist,
      activeExpert: this.isExpert,
      activeListener: this.isListener,
      goLivePermission: this.isGoAnyoLive,
      title: this.title,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      mobile: this.phoneNo,
      languagesKnown: this.selectedLanguage,
      desginationLevel: this.selectedTherapistLevel,
      rating: this.rating,
      pricing: {
        minPricing: this.minPricing,
        maxPricing: this.maxPricing,
      },
      userInterest: this.userInterestTopics,
      tagLine: this.tagLine,
      verticle: this.verticalIds,
      concern: this.concernIds,
      concernExpertLevelMap: this.concernExpertLevelMap,
      yearsOfExperience: this.yearOfExperiance,
      profilePicture: this.profilePic,
      aboutTherapist: this.about,
      videoBio: this.videoSrc,
      avatarName: this.avatarName,
      avatarPic: this.avatar,
    };
    if (this.isGoAnyoLive && (!this.anyoTalks || !this.anyoTalks.length)) {
      this.toastrService.showError('Please select a live');
      return;
    }
    if (this.videoBioFile) {
      const uuid = uuidv4();
      const uuidWithoutHyphens = uuid.replace(/-/g, '');
      const extension = this.videoBioFile.name.split('.').pop();
      const videoBioFilePath = `therapist/${uuidWithoutHyphens}.${extension}`;
      const uploadUrl = await lastValueFrom(
        this.fileService.generateUploadUrl(
          AnyoS3Buckets.CDN_BUCKET,
          videoBioFilePath,
          this.videoBioFile.type,
        ),
      );
      this.toastrService.showSuccess(`Uploading Therapist Video Bio`);
      await lastValueFrom(
        this.fileService.uploadFile(this.videoBioFile, uploadUrl.url),
      );
      this.toastrService.showSuccess(`Uploading Therapist Video Bio completed`);
      newExperts.videoBio = videoBioFilePath;
    }
    if (this.videoBioFile) {
      newExperts.videoBio = await this.saveMediaFiles(
        this.videoBioFile,
        'therapist',
        'Video Bio',
      );
    }

    if (this.therapistImageFile) {
      newExperts.profilePicture = await this.saveMediaFiles(
        this.therapistImageFile,
        'therapist',
        'Profile Pic',
      );
    }
    this.experts.createExpert(newExperts).subscribe({
      next: () => {
        this.toastrService.showSuccess('Successfully created');
        this.router.navigate(['/experts']);
        this.pageLoading = false;
      },
      error: (err) => {
        const e = err.error as IAnyoError;
        this.toastrService.showAnyoErrorToast(e.description);
        this.pageLoading = false;
      },
    });
  }

  override async get(userId: string) {
    this.pageLoading = true;
    this.experts.getExpertTherapistListener(userId).subscribe({
      next: (value) => {
        this.therapistSlug = value.slug;
        this.selectedAnyoTalks = value.anyotalksIds;
        this.isTherapist = value.activeTherapist;
        this.isExpert = value.activeExpert;
        this.isListener = value.activeListener;
        this.title = value.title;
        this.firstName = value.firstName;
        this.lastName = value.lastName;
        this.email = value.email;
        this.phoneNo = value.mobile;
        this.selectedLanguage = value.languagesKnown;
        this.selectedTherapistLevel = value.desginationLevel;
        this.rating = value.rating;
        this.maxPricing = value.pricing?.maxPricing || 0;
        this.minPricing = value.pricing?.minPricing || 0;
        this.selectedUserInterests =
          value.userInterest && value.userInterest.length > 0
            ? value.userInterest.map((data) => data.key)
            : [];
        this.tagLine = value.tagLine;
        this.verticalIds = value.verticle;
        this.concernIds = value.concern;
        this.yearOfExperiance = value.yearsOfExperience;
        this.profilePic = value.profilePicture;
        this.about = value.aboutTherapist;
        this.videoSrc = value.videoBio;
        this.avatarName = value.avatarName;
        this.avatar = value.avatarPic;
        this.isGoAnyoLive = value.goLivePermission;
        if (value.verticle && value.verticle.length) {
          this.expertiseLevelArray = [];
          value.verticle.forEach((value3) => {
            const item: ExpertLevelArray = {
              verticalId: value3,
              verticalName: '',
              concernsList: this.concernsMasterData
                .filter((value1) => value1.verticalId.includes(value3))
                .map((value2) => {
                  return {
                    ...value2,
                    disabled: value.concern.includes(value2.value),
                  };
                }),
              concerns: [],
            };
            item.concernsList.forEach((v1) => {
              const expertLevel = value.concernExpertLevelMap[v1.value];
              if (expertLevel) {
                const it = {
                  concernsId: v1.value,
                  concernName: v1.label,
                  expertLevelId: expertLevel.id,
                  expertLevel: '',
                };
                item.concerns.push(it);
              }
            });
            if (!item.concerns.length) {
              item.concerns.push({
                concernsId: '',
                concernName: '',
                expertLevelId: '',
                expertLevel: '',
              });
            }
            this.expertiseLevelArray.push(item);
          });
          this.verticalsMasterDataFiltered = this.verticalsMasterData.map(
            (value1) => {
              return {
                ...value1,
                disabled: value.verticle.includes(value1.value),
              };
            },
          );
        }
        this.pageLoading = false;
      },
      error: (err) => {
        const e = err.error as IAnyoError;
        this.toastrService.showAnyoErrorToast(e.description);
        this.pageLoading = false;
      },
    });
  }

  async saveMediaFiles(file: File, pathName: string, toastText: string) {
    const uuid = uuidv4();
    const uuidWithoutHyphens = uuid.replace(/-/g, '');
    const extension = file.name.split('.').pop();
    const filePath = `${pathName}/${uuidWithoutHyphens}.${extension}`;
    const uploadUrl = await lastValueFrom(
      this.fileService.generateUploadUrl(
        AnyoS3Buckets.CDN_BUCKET,
        filePath,
        file.type,
      ),
    );
    this.toastrService.showSuccess(`Uploading ${toastText}`);
    await lastValueFrom(this.fileService.uploadFile(file, uploadUrl.url));
    this.toastrService.showSuccess(`Uploading ${toastText} completed`);
    return `https://cdn.anyo.app/${filePath}`;
  }

  override async update(): Promise<void> {
    this.pageLoading = true;
    if (this.validation()) {
      return;
    }
    this.verticalIds = this.expertiseLevelArray.map(
      (value) => value.verticalId,
    );
    this.concernIds = this.expertiseLevelArray
      .map((value) => value.concerns.map((concern) => concern.concernsId))
      .flat();
    this.concernExpertLevelMap = this.expertiseLevelArray.reduce(
      (
        acc: Record<
          string,
          {
            level: string;
            id: string;
          }
        >,
        value,
      ) => {
        value.concerns.forEach((concern) => {
          acc[concern.concernsId] = {
            id: concern.expertLevelId,
            level: concern.expertLevel,
          };
        });
        return acc;
      },
      {},
    );

    this.userInterestTopics = this.userInterestTopics
      .filter((value) => this.selectedUserInterests.includes(value.key))
      .map((value) => {
        return {
          key: value.key,
          value: value.value,
        };
      });
    const updateExpert: CreateExpertTherapistListenerRequest = {
      slug: this.therapistSlug,
      archived: this.archived,
      anyotalksIds: this.selectedAnyoTalks,
      activeTherapist: this.isTherapist,
      activeExpert: this.isExpert,
      activeListener: this.isListener,
      goLivePermission: this.isGoAnyoLive,
      title: this.title,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      mobile: this.phoneNo,
      languagesKnown: this.selectedLanguage,
      desginationLevel: this.selectedTherapistLevel,
      rating: this.rating,
      pricing: {
        minPricing: this.minPricing,
        maxPricing: this.maxPricing,
      },
      userInterest: this.userInterestTopics.filter((data) =>
        this.selectedUserInterests.includes(data.key),
      ),
      tagLine: this.tagLine,
      verticle: this.verticalIds,
      concern: this.concernIds,
      concernExpertLevelMap: this.concernExpertLevelMap,
      yearsOfExperience: this.yearOfExperiance,
      profilePicture: this.profilePic,
      aboutTherapist: this.about,
      videoBio: this.videoSrc,
      avatarName: this.avatarName,
      avatarPic: this.avatar,
    };

    if (this.videoBioFile) {
      updateExpert.videoBio = await this.saveMediaFiles(
        this.videoBioFile,
        'therapist',
        'Video Bio',
      );
    }

    if (this.therapistImageFile) {
      updateExpert.profilePicture = await this.saveMediaFiles(
        this.therapistImageFile,
        'therapist',
        'Profile Pic',
      );
    }
    this.experts.editExpert(this.userId, updateExpert).subscribe({
      next: () => {
        this.router.navigate(['/experts']);
        this.toastrService.showSuccess('Successfully Updated');
        this.pageLoading = false;
      },
      error: (err) => {
        const e = err.error as IAnyoError;
        this.toastrService.showAnyoErrorToast(e.description);
        this.pageLoading = false;
      },
    });
  }
}

import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NzAutosizeDirective, NzInputDirective} from 'ng-zorro-antd/input';
import {NzButtonComponent} from 'ng-zorro-antd/button';
import {NzCardComponent} from 'ng-zorro-antd/card';
import {NzCheckboxComponent} from 'ng-zorro-antd/checkbox';
import {NzColDirective, NzRowDirective} from 'ng-zorro-antd/grid';
import {NzDividerComponent} from 'ng-zorro-antd/divider';
import {NzOptionComponent, NzSelectComponent} from 'ng-zorro-antd/select';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NzSpinComponent} from 'ng-zorro-antd/spin';
import {ToastService} from '../../../../../app-core/src/app/services/toastr.service';
import {ChatV2Service} from '../../services/chat-v2.service';
import {NgForOf, NgIf} from '@angular/common';
import {NzIconDirective} from 'ng-zorro-antd/icon';
import {MasterdataService} from '../../../../../admin/src/app/services/masterdata.service';
import {ChatProfilingGroups} from '../../../../../admin/src/app/models/masterDataResponse';
import {IAnyoError} from '../../../../../app-core/src/models/errorModal';

export interface ChatSummaryRequest {
  chatSessionId: string;
  summary: string;
  keyActions: string;
  therapySuggested: boolean;
  issueProfile: IssueProfile[];
}

interface IssueProfile {
  concernId: string;
  subConcernId: string;
}

interface CategorySelect {
  id: string;
  label: string;
}

interface SubCategorySelect {
  categoryId: string;
  id: string;
  label: string;
}

@Component({
  selector: 'app-session-feedback',
  standalone: true,
  imports: [
    NzAutosizeDirective,
    NzButtonComponent,
    NzCardComponent,
    NzCheckboxComponent,
    NzColDirective,
    NzDividerComponent,
    NzInputDirective,
    NzOptionComponent,
    NzRowDirective,
    NzSelectComponent,
    ReactiveFormsModule,
    FormsModule,
    NzSpinComponent,
    NgForOf,
    NzIconDirective,
    NgIf,
  ],
  templateUrl: './session-feedback.component.html',
  styleUrl: './session-feedback.component.scss',
})
export class SessionFeedbackComponent {
  issueProfiles: IssueProfile[] = [
    {
      concernId: '',
      subConcernId: '',
    },
  ];
  @Input() sessionId?: string;
  loading: boolean = false;
  chatSummary: string | undefined;
  therapySuggested: boolean | undefined;
  keyActionsSuggested: string | undefined;
  @Output() sessionUpdateComplete = new EventEmitter<void>();
  categorySelect: CategorySelect[] = [];
  subCategorySelect: SubCategorySelect[] = [];
  private selectedCategoryId: string | undefined;
  private chatProfilingGroups: ChatProfilingGroups[] = [];

  constructor(
    private toastService: ToastService,
    private chatV2Service: ChatV2Service,
    private masterDataService: MasterdataService,
  ) {
  }

  ngOnInit() {
    this.masterDataService.masterDataList().subscribe((value) => {
      value.chatProfilingGroups.forEach((group) => {
        this.chatProfilingGroups = value.chatProfilingGroups;
        this.categorySelect.push({id: group._id, label: group.category});
      });
    });
  }

  submitFeedback() {
    if (!this.chatSummary) {
      this.toastService.showError('Please enter a chat summary');
      return;
    }
    if (this.issueProfiles.length == 0) {
      this.toastService.showError('Please add at least one issue profile');
      return;
    }
    const payload: ChatSummaryRequest = {
      chatSessionId: this.sessionId!,
      summary: this.chatSummary,
      keyActions: this.keyActionsSuggested!,
      therapySuggested: this.therapySuggested!,
      issueProfile: this.issueProfiles!,
    };
    this.loading = true;
    this.chatV2Service.submitChatFeedback(payload).subscribe({
      next: (value) => {
        this.toastService.showSuccess('Feedback submitted successfully');
        this.loading = false;
        this.sessionUpdateComplete.emit();
      },
      error: (error) => {
        const anyoError = error.error as IAnyoError;
        this.toastService.showError(
          'Error submitting feedback ' + anyoError.description,
        );
        this.loading = false;
      },
    });
  }

  addIssueProfile() {
    this.issueProfiles.push({
      concernId: '',
      subConcernId: '',
    });
  }

  deleteIssueProfile(index: number) {
    this.issueProfiles.splice(index, 1);
  }

  categorySelectedFn($event: string) {
    this.selectedCategoryId = $event;
    this.subCategorySelect = [];
    this.chatProfilingGroups
      .filter((group) => group._id == this.selectedCategoryId)
      .forEach((group) => {
        group.subCategory.forEach((subCategory) => {
          this.subCategorySelect.push({
            categoryId: group._id,
            id: subCategory.key,
            label: subCategory.value,
          });
        });
      });
  }
}

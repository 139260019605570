<div class="flex flex-row md:gap-2">
  <label class="client-initial">Home</label>
</div>

<div class="custom-card">
  <div class="card-body flex flex-col">
    <div class="flex flex-row md:gap-8">
      <div class="left-column">
        <div class="card-home">
          <div class="card-body">
            <div class="flex flex-col"></div>
            <div class="flex justify-center">
              <div class="avatar avatar-ring">
                <img src="assets/icons/avatar1.svg" alt="avatar" />
              </div>
            </div>
            <label class="name-left">{{ listener?.name }}</label>
            <label class="avatar-name">{{ listener?.avtarName }}</label>
            <div class="flex flex-row justify-center pl-16">
              <div
                class=" {{ listenerRating >= 1 ? 'filled' : 'unfilled' }}"
              ></div>
              <div
                class=" {{ listenerRating >= 2 ? 'filled' : 'unfilled' }}"
              ></div>
              <div
                class=" {{ listenerRating >= 3 ? 'filled' : 'unfilled' }}"
              ></div>
              <div
                class=" {{ listenerRating >= 4 ? 'filled' : 'unfilled' }}"
              ></div>
              <div
                class=" {{ listenerRating >= 5 ? 'filled' : 'unfilled' }}"
              ></div>
            </div>
            <label class="heading-left">About</label>
            <p class="left-para">{{ listener?.about }}</p>
            <div class="heading-left">Expertise</div>
            {{ listener?.specializationReadable }}
            <div>
              <app-circled-input-listener
                [array]="listener?.specializationReadable"
              ></app-circled-input-listener>
            </div>
          </div>
        </div>
      </div>
      <div class="right-column">
        <div class="flex flex-col md:gap-2">
          <div class="right-heading">Overall Log</div>
          <div class="flex flex-row md:gap-4">
            <app-card-listener
              [cardValue]="listener?.totalChats!.toString()"
              [cardContent]="overallContent1"
            ></app-card-listener>
            <app-card-listener
              [cardValue]="listener?.totalUsers!.toString()"
              [cardContent]="overallContent2"
            ></app-card-listener>
            <app-card-listener
              [cardValue]="listener?.rating!.toString()"
              [cardContent]="overallContent3"
            ></app-card-listener>
          </div>
          <div class="right-heading">This Month</div>
          <div class="flex flex-row md:gap-4">
            <app-card-listener
              [cardValue]="listener?.totalChats!.toString()"
              [cardContent]="overallContent1"
            ></app-card-listener>
            <app-card-listener
              [cardValue]="listener?.totalUsers!.toString()"
              [cardContent]="overallContent2"
            ></app-card-listener>
            <app-card-listener
              [cardValue]="listener?.rating!.toString()"
              [cardContent]="overallContent3"
            ></app-card-listener>
          </div>
          <div class="right-heading">This Week</div>
          <div class="flex flex-row md:gap-4">
            <app-card-listener
              [cardValue]="listener?.totalChats!.toString()"
              [cardContent]="overallContent1"
            ></app-card-listener>
            <app-card-listener
              [cardValue]="listener?.totalUsers!.toString()"
              [cardContent]="overallContent2"
            ></app-card-listener>
            <app-card-listener
              [cardValue]="listener?.rating!.toString()"
              [cardContent]="overallContent3"
            ></app-card-listener>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

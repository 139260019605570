import { LAST_MEDIA } from '@angular/cdk/keycodes';
import { Component, HostListener } from '@angular/core';
import { Observable, from, lastValueFrom } from 'rxjs';

import { ListenerService } from '../../services/listener.service';
import { AuthService } from '../../../../../app-core/src/auth/auth.service';
import IAnyoListener from '../../models/IAnyoListener';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  constructor(
    private routerService: Router,
    private listenerService: ListenerService,
    private authService: AuthService,
  ) {
    this.authService.currentAuthStatus.subscribe((user) => {
      if (user) {
        this.listenerService.getListener().subscribe(
          (listener) => {
            if (listener) {
              this.listenerService
                .lastSeenlistenerStamp(listener._id)
                .subscribe({
                  next: (value) => {
                    console.log(value);
                  },
                  error: (err) => {
                    console.log(err);
                  },
                });
              this.listener = listener;
            }
          },
          async (error) => {
            console.log('fetchListener', error);
            await this.authService.signOut();
            await this.routerService.navigate(['/login']);
          },
        );
      }
    });
  }

  overallValue1: string = '100';
  overallContent1: string = 'Total No of Chats';
  overallValue2: string = '1000';
  overallContent2: string = 'No of users';
  overallValue3: string = '4.2';
  overallContent3: string = 'Average Rating';
  categoryArray: any[] = [];
  checked: boolean = false;
  isAlert: boolean = false;
  header: string = 'Confirmation';
  alert: boolean = false;
  listener: IAnyoListener | undefined;
  userId: string = '';
  Psychodynamic: string = '';
  listenerRating: any;
  async ngOnInit(): Promise<void> {}
  name = 'Angular';
}

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonComponentModule } from 'projects/app-core/src/app/components/commoncomponent.module';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from 'projects/admin/src/app/app.module';
import { ListenerAuthModule } from './listener-auth/listener-auth.module';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import en from '@angular/common/locales/en';
import { getAnalytics, provideAnalytics } from '@angular/fire/analytics';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { getPerformance, providePerformance } from '@angular/fire/performance';
import {
  getRemoteConfig,
  provideRemoteConfig,
} from '@angular/fire/remote-config';
import { MainLayoutComponent } from './components/main-layout/main-layout.component';
import { ListenerHomeComponent } from './components/listener-home/listener-home.component';
import { HomeComponent } from './pages/home/home.component';
import { ChatComponent } from './pages/chat/chat.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { TokenV2Interceptor } from '../../../app-core/src/interceptors/tokenV2.interceptor';
import { NzSpinComponent, NzSpinModule } from 'ng-zorro-antd/spin';
import { registerLocaleData } from '@angular/common';
import { getDatabase, provideDatabase } from '@angular/fire/database';
import {
  NzColDirective,
  NzGridModule,
  NzRowDirective,
} from 'ng-zorro-antd/grid';
import { NzCardComponent } from 'ng-zorro-antd/card';
import { NzDividerComponent, NzDividerModule } from 'ng-zorro-antd/divider';
import { NzTagComponent, NzTagModule } from 'ng-zorro-antd/tag';
import {
  NzOptionComponent,
  NzSelectComponent,
  NzSelectModule,
} from 'ng-zorro-antd/select';
import { NzButtonComponent, NzButtonModule } from 'ng-zorro-antd/button';
import {
  NzAutosizeDirective,
  NzInputDirective,
  NzInputModule,
} from 'ng-zorro-antd/input';
import { NzCheckboxComponent, NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { SessionFeedbackComponent } from './components/session-feedback/session-feedback.component';
import {
  NzModalComponent,
  NzModalContentDirective,
  NzModalModule,
} from 'ng-zorro-antd/modal';
import {
  NzCollapseComponent,
  NzCollapsePanelComponent,
} from 'ng-zorro-antd/collapse';
import { NzSkeletonComponent } from 'ng-zorro-antd/skeleton';
import { NzEmptyComponent } from 'ng-zorro-antd/empty';
import { en_US, provideNzI18n } from 'ng-zorro-antd/i18n';
import { NzIconDirective, NzIconModule } from 'ng-zorro-antd/icon';
import {
  NzDrawerComponent,
  NzDrawerContentDirective,
} from 'ng-zorro-antd/drawer';
import { NzTableComponent, NzTableModule } from 'ng-zorro-antd/table';
import { PostsModule } from 'projects/app-core/src/app/posts/posts.module';
import {
  NzDatePickerComponent,
  NzDatePickerModule,
} from 'ng-zorro-antd/date-picker';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { SessionModule } from 'projects/app-core/src/app/session/session.module';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import {
  DatabaseOutline,
  DownloadOutline,
  FilterOutline,
  InfoOutline,
  PlusOutline,
  ProfileOutline,
  ScheduleOutline,
} from '@ant-design/icons-angular/icons';

registerLocaleData(en);

@NgModule({
  declarations: [
    AppComponent,
    MainLayoutComponent,
    ListenerHomeComponent,
    HomeComponent,
    ChatComponent,
  ],
  imports: [
    InfiniteScrollModule,
    BrowserModule,
    AppRoutingModule,
    CommonComponentModule,
    HttpClientModule,
    FormsModule,
    ListenerAuthModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideDatabase(() => getDatabase()),
    provideAnalytics(() => getAnalytics()),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    provideMessaging(() => getMessaging()),
    providePerformance(() => getPerformance()),
    provideRemoteConfig(() => getRemoteConfig()),
    SessionModule,
    NzSpinComponent,
    NzRowDirective,
    NzColDirective,
    NzCardComponent,
    NzDividerComponent,
    NzTagComponent,
    NzSelectComponent,
    NzOptionComponent,
    NzButtonComponent,
    NzInputDirective,
    NzAutosizeDirective,
    NzCheckboxComponent,
    SessionFeedbackComponent,
    NzModalComponent,
    NzCollapseComponent,
    NzCollapsePanelComponent,
    NzSkeletonComponent,
    NzEmptyComponent,
    NzIconDirective,
    NzDrawerComponent,
    NzDrawerContentDirective,
    NzTableComponent,
    PostsModule,
    NzInputModule,
    NzTableModule,
    NzSpinModule,
    NzButtonModule,
    NzDatePickerComponent,
    NzModalContentDirective,
    NzTabsModule,
    NzIconModule.forRoot([
      FilterOutline,
      ScheduleOutline,
      DatabaseOutline,
      PlusOutline,
      DownloadOutline,
      ProfileOutline,
      InfoOutline,
    ]),
    NzToolTipModule,
    NzAlertModule,
    NzAvatarModule,
    NzDropDownModule,
    NzGridModule,
    NzDatePickerModule,
    NzCheckboxModule,
    NzDividerModule,
    NzSwitchModule,
    NzModalModule,
    NzRadioModule,
    NzSelectModule,
    NzAutocompleteModule,
    NzTimePickerModule,
    NzTagModule,
  ],
  providers: [
    provideNzI18n(en_US),
    { provide: FIREBASE_OPTIONS, useValue: environment.firebase },
    { provide: HTTP_INTERCEPTORS, useClass: TokenV2Interceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

import { Component, ElementRef, ViewChild } from '@angular/core';

import { ToastrService } from 'ngx-toastr';

import { ListenerService } from '../../services/listener.service';
import { ChatV2Service } from '../../services/chat-v2.service';
import { ChatUserTypes, ChatV2Messages } from '../../models/chatV2Messages';
import { ChatV2Sessions } from '../../models/chatV2Sessions';
import IAnyoListener from '../../models/IAnyoListener';
import { Timestamp } from 'firebase/compat/firestore/dist/firestore';
import { ChatV2NewRequest } from '../../models/chatV2NewRequest';
import { PostHogService } from '../../services/post-hog.service';
import * as moment from 'moment';
import { AuthService } from '../../../../../app-core/src/auth/auth.service';
import {
  NetworkUtilsService,
  URL_DICT,
} from '../../../../../app-core/src/service/network-utils.service';
import { lastValueFrom } from 'rxjs';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { ChatSummaryListResponse } from '../../models/chhatSummaryListResponse';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';

export interface newChatI {
  requestId: string;
  name: string;
  email?: string;
  role?: string;
  age?: string;
  isSelected: boolean;
  gender: string;
  specialization: string[];
  expertChat?: boolean;
  circleName?: string;
}

interface pendingSummaryChats {
  sessionId: string;
  userConcern: string[];
  userName: string;
  chatTime: string;
  startTime: string;
}

export interface chatSessionI {
  live: boolean;
  sessionId: string;
  name: string;
  email?: string;
  role?: string;
  age?: string;
  isSelected: boolean;
  gender: string;
  messages: messageI[];
  listenerRating: string;
  userConcerns: string[];
  startTime?: moment.Moment;
  userId: string;
  circleName?: string;
  expertChat?: boolean;
  userTyping: boolean;
}

interface messageI {
  messageType?: ChatUserTypes;
  senderId: string;
  sentAt: Date;
  message: string;
  sessionId: string;
}

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent {
  typingTimeout: NodeJS.Timeout | undefined;
  onKeyDown($event: KeyboardEvent) {
    if (!this.typingTimeout) {
      this.chatV2Service.setListenerTyping(this.sessionId, true);
    }
    clearTimeout(this.typingTimeout);
    this.typingTimeout = setTimeout(() => {
      this.chatV2Service.setListenerTyping(this.sessionId, false);
      this.typingTimeout = undefined;
    }, 5000);
  }
  listenerOnline = false;
  chatButtonLoader = false;
  @ViewChild('inputMessage') inputMessage!: ElementRef;
  showLoader: boolean = true;
  chatDuration: any = '00:00:00';
  pendingSummaryChats: pendingSummaryChats[] = [];
  showSessionPane: boolean = false;
  alertShown: boolean = false;

  chatHeader: boolean = false;
  alert: boolean = false;
  chatName: string = '';
  ratingHeader: string = 'How would you rate user   ';
  rejectReason: string = 'Reason';
  endAlert: boolean = false;
  rejectAlert: boolean = false;
  rateAlert: boolean = false;
  listenerObj: IAnyoListener | undefined = undefined;
  rateName: string = ' ';
  chatRequests: newChatI[] = [];
  chatSessions: chatSessionI[] = [];
  selectedUser: chatSessionI | undefined;
  sessionId: string = '';
  messageListeners = new Map<string, boolean>();
  rejectIndex: string = '';
  interval!: any;
  rejectHeader: string = 'Reject Chat';
  selectedUserConcerns: string[] = [];
  formatDateToTimeString(date: Date): string {
    return moment(date).format('hh:mm:ss A');
  }

  @ViewChild('chatContainer') chatContainerRef!: ElementRef;

  autoExpandInput(element: any) {
    element.style.height = 'auto'; // Reset the height to auto
    element.style.height = element.scrollHeight + 'px'; // Set the height to the scroll height
  }

  oldMessages: messageI[] = [];
  chatSummaryList: ChatSummaryListResponse[] = [];

  rateBox() {
    this.rateAlert = true;
    this.endAlert = false;
    if (this.selectedUser) {
      this.chatSessions.splice(this.chatSessions.indexOf(this.selectedUser), 1);
      this.selectedUser = undefined;
      this.chatHeader = false;
      this.chatName = '';
    }
  }
  confirmModal?: NzModalRef;

  onKeyPress(event: KeyboardEvent) {
    if (event.key == 'Enter') {
      this.sendMessage(this.sessionId);
    }
  }

  async confirm(newChatRequest: newChatI, index: number) {
    const newSessionId = await this.chatV2Service.acceptChat(
      newChatRequest.requestId,
    );
    if (newSessionId) {
      const sessionIndex = this.chatSessions.findIndex(
        (value) => value.sessionId === newSessionId,
      );
      if (sessionIndex !== -1) {
        this.chatSelection(this.chatSessions[sessionIndex]);
      }
    }
    this.chatRequests.splice(index, 1);
    setTimeout(() => {
      if (this.inputMessage) this.inputMessage.nativeElement.focus();
    }, 300);
  }

  cancel(requestId: string) {
    this.rejectIndex = requestId;
    this.rejectAlert = true;
  }
  showHistoryDrawer: boolean = false;

  selectedName(name: string) {
    this.chatHeader = true;
    this.chatName = name;
    const firstLetter = name.charAt(0).toLowerCase();
    const remainingPart = name.slice(1);
    this.rateName = firstLetter + remainingPart;
  }
  historyTableLoading: boolean = false;

  currentMessage: string = '';
  time: string = ' ';
  submitFeedbackSessionId: string | undefined;

  async reject(event: string) {
    try {
      if (event.length !== 0) {
        const chatRequest = this.chatRequests.find(
          (request) => request.requestId === this.rejectIndex,
        );
        if (chatRequest) {
          await this.chatV2Service.rejectChat(chatRequest.requestId, event);
          this.rejectAlert = false;
        }
      }
    } catch (e) {
      console.log(e);
      this.toastService.error('Something went wrong');
    }
  }

  constructor(
    private listenerService: ListenerService,
    private postHog: PostHogService,
    private toastService: ToastrService,
    private chatV2Service: ChatV2Service,
    private auth: AuthService,
    private api: NetworkUtilsService,
    private modal: NzModalService,
    private messaging: AngularFireMessaging,
  ) {}

  closeRejectAlert() {
    this.rejectAlert = false;
  }

  async sendMessage(sessionId: string) {
    this.currentMessage = this.currentMessage.trim();
    if (!this.currentMessage) {
      this.toastService.error('Please input some message');
      return;
    }
    await this.chatV2Service.sendNewMessage(
      this.currentMessage,
      sessionId,
      this.listenerObj!._id,
    );
    this.currentMessage = '';
  }

  async fetchPendingSummaryChats() {
    try {
      this.chatV2Service.fetchPendingFeedbackSessions().subscribe({
        next: (value) => {
          this.pendingSummaryChats = value.map((value1) => {
            const data: pendingSummaryChats = {
              startTime: moment(value1.startTime, 'x').format(
                'DD/MM/YY hh:mm a',
              ),
              chatTime:
                Math.ceil(
                  moment
                    .duration(
                      moment(value1.completedOn, 'x').diff(
                        moment(value1.startTime, 'x'),
                      ),
                    )
                    .as('minutes'),
                ) + ' minutes',
              sessionId: value1.chatSessionId,
              userName: value1.userName,
              userConcern: value1.userConcerns,
            };
            return data;
          });
        },
        error: (err) => {
          this.toastService.error(
            'Error while fetching pending feedback chats' + err,
          );
        },
      });
    } catch (e) {}
  }

  end() {
    this.modal.confirm({
      nzContent: 'Do you really want to end Chat ?',
      nzTitle: 'End Chat',
      nzOkText: 'End Chat',
      nzOkDanger: true,
      nzOnOk: () => {
        this.rateBox();
      },
      nzOnCancel: () => {
        return;
      },
      nzCancelText: 'Cancel',
    });
  }

  async showSessionSummary(sessionId: string) {
    this.submitFeedbackSessionId = sessionId;
    this.showSessionPane = true;
    const messagesSnap = await lastValueFrom(
      this.chatV2Service.getMessages(sessionId),
    );
    this.oldMessages = messagesSnap.docs
      .map((value) => {
        const data = value.data() as ChatV2Messages;
        const message: messageI = {
          message: data.message,
          senderId: data.senderId,
          sentAt: (data.timestamp as unknown as Timestamp).toDate(),
          sessionId: sessionId,
          messageType: data.senderType,
        };
        return message;
      })
      .sort((a, b) => {
        return a.sentAt.getTime() - b.sentAt.getTime();
      });
  }

  async sessionUpdateComplete() {
    await this.fetchPendingSummaryChats();
    this.showSessionPane = false;
  }

  expertOnline: boolean = false;

  async feedRate(event: any) {
    if (event.starValue <= 2) {
      if (event.rateFeedback.length !== 0) {
        const rating = event.starValue.toString();
        const feedback = event.rateFeedback;
        this.rateAlert = false;
        // await this.ratingFeedback(rating, feedback, this.sessionId);
        await this.chatV2Service.endChat(this.sessionId, rating, feedback);
      } else {
        this.rateAlert = true;
        this.toastService.error('Feedback is mandatory');
      }
    } else {
      const rating = event.starValue.toString();
      const feedback = event.rateFeedback;
      this.rateAlert = false;
      // await this.ratingFeedback(rating, feedback, this.sessionId);
      await this.chatV2Service.endChat(this.sessionId, rating, feedback);
    }
    await this.fetchPendingSummaryChats();
    this.chatName = '';
  }

  chatSelection(item: chatSessionI) {
    this.currentMessage = '';
    this.showSessionPane = false;
    this.chatSessions.forEach((data) => (data.isSelected = false));
    this.selectedUser = item;
    item.isSelected = true;
    this.selectedUserConcerns = item.userConcerns;
    this.selectedName(item.name);
    this.sessionId = item.sessionId;
    setTimeout(() => {
      if (this.inputMessage) {
        this.inputMessage.nativeElement.focus();
      }
    }, 300);

    if (this.interval !== undefined) {
      clearInterval(this.interval);
    }
    this.interval = setInterval(() => {
      const startMoment = moment(item.startTime);
      const currentMoment = moment();
      let timer = currentMoment.diff(startMoment, 'milliseconds');
      this.chatDuration = moment.utc(timer).format('HH:mm:ss');
    }, 1000);
    const sessionIndex = this.chatSessions.findIndex(
      (ses) => ses.sessionId === item.sessionId,
    );
    if (!item.live) {
      if (!item.listenerRating) {
        if (this.sessionId === item.sessionId) {
          this.rateBox();
        }
      } else {
        this.selectedUser = undefined;
        this.chatHeader = false;
        this.chatName = '';
        this.chatSessions.splice(sessionIndex, 1);
      }
    }
    if (!this.messageListeners.get(item.sessionId)) {
      this.messageListeners.set(item.sessionId, true);
      this.chatV2Service
        .chatSessionMessageObserver(item.sessionId)
        .subscribe((value) => {
          const sessionIndex = this.chatSessions.findIndex(
            (ses) => ses.sessionId === item.sessionId,
          );
          if (this.sessionId !== item.sessionId) {
            const audio = new Audio('assets/icons/notification sound.mp3');
            audio.play();
            this.toastService.show('New message from ' + item.name);
          }
          item.messages = value
            .map((message) => message.payload.doc.data() as ChatV2Messages)
            .map((value1) => {
              return {
                message: value1.message,
                senderId: value1.senderId,
                sentAt: (value1.timestamp as unknown as Timestamp).toDate(),
                sessionId: item.sessionId,
                messageType: value1.senderType,
              };
            });
          this.chatSessions[sessionIndex].messages = item.messages;
          setTimeout(() => {
            const t = document.getElementById(
              item.messages.length - 1 + item.sessionId,
            );
            if (t) {
              t.scrollIntoView({ block: 'end' });
            }
          }, 100);
        });
      this.chatV2Service
        .chatSessionObserver(item.sessionId)
        .subscribe((value) => {
          const session = value.payload.data() as ChatV2Sessions;
          const sessionIndex = this.chatSessions.findIndex(
            (ses) => ses.sessionId === item.sessionId,
          );
          this.chatSessions[sessionIndex].userTyping = session.userTyping;
          if (this.chatSessions[sessionIndex]) {
            this.chatSessions[sessionIndex].listenerRating =
              session.listenerRating;
            this.chatSessions[sessionIndex].live = session.live;
          }
          if (!session.live) {
            this.chatName = '';
            this.toastService.show(
              'Chat session ended by ' + this.chatSessions[sessionIndex].name,
            );
          }
        });
    }
    setTimeout(() => {
      const t = document.getElementById(
        item.messages.length - 1 + item.sessionId,
      );
      if (t) {
        t.scrollIntoView({ block: 'end' });
      }
    }, 100);
  }
  showExpertNextAvailableModal: boolean = false;

  hideHistoryDrawerFn = () => {
    this.showHistoryDrawer = false;
  };

  showHistoryDrawerFn = () => {
    const session = this.chatSessions.find(
      (value) => value.sessionId === this.sessionId,
    );
    this.showHistoryDrawer = true;
    this.historyTableLoading = true;
    this.chatV2Service.fetchChatSummary(session!.userId).subscribe({
      next: (value) => {
        this.historyTableLoading = false;
        this.chatSummaryList = value.map((value1) => {
          const issues: string[] = [];
          value1.issueProfile?.forEach((value2) => {
            issues.push(value2.concern);
            issues.push(value2.subConcern);
          });
          const data: ChatSummaryListResponse = {
            listenerName: value1.listenerName,
            summaryCompletedOn: value1.summaryCompletedOn,
            summary: value1.summary,
            keyActions: value1.keyActions,
            therapySuggested: value1.therapySuggested,
            therapySuggestedString: value1.therapySuggested ? 'Yes' : 'No',
            summaryCompletedOnString: moment(
              value1.summaryCompletedOn,
              'x',
            ).format('DD/MM/YY hh:mm a'),
            issueProfile: value1.issueProfile,
            issueProfileFlat: issues,
          };
          return data;
        });
      },
      error: (err) => {
        this.historyTableLoading = false;
        this.toastService.error('Error while fetching chat summary' + err);
      },
    });
  };
  expertNextAvailableAt: Date | undefined;

  async updateListenerAvailabilityStatus(event: Event): Promise<void> {
    const checkBox = event.target as HTMLInputElement;
    event.stopPropagation();
    this.chatButtonLoader = true;

    if (checkBox.checked) {
      this.confirmModal = this.modal.confirm({
        nzTitle: 'Do you Want to go online as Listener?',
        nzContent: 'Please make sure you want to be online as Listener',
        nzOnOk: async () => {
          await this.chatV2Service.setListenerToOnline();
          this.chatButtonLoader = false;
        },
        nzOnCancel: async () => {
          await this.chatV2Service.setListenerToOffLine();
          this.chatButtonLoader = false;
        },
        nzOkText: 'Go Online',
        nzCancelText: 'Cancel',
        nzClosable: false,
      });
    } else {
      if (await this.chatV2Service.checkActiveChatsAndRequests()) {
        this.toastService.error(
          'Please end all live chats and new Chat requests',
        );
        await this.chatV2Service.setListenerToOnline();
        this.chatButtonLoader = false;
        return;
      }
      this.confirmModal = this.modal.confirm({
        nzTitle: 'Do you Want to go offline as Listener?',
        nzContent:
          'Please make sure you want to be offline as Listener. Being offline does not allow you to take the chats',
        nzOnOk: async () => {
          await this.chatV2Service.setListenerToOffLine();
          this.chatButtonLoader = false;
        },
        nzOnCancel: async () => {
          await this.chatV2Service.setListenerToOnline();
          this.chatButtonLoader = false;
        },
        nzOkText: 'Go Offline',
        nzCancelText: 'Cancel',
        nzClosable: false,
      });
    }
  }

  async ngOnInit() {
    this.showLoader = true;
    this.chatButtonLoader = true;
    this.auth.currentAuthStatus.subscribe((user) => {
      if (user) {
        this.chatV2Service.listenerSub.subscribe(async (lis) => {
          if (lis?._id) {
            await Notification.requestPermission();
            this.messaging.requestPermission.subscribe({
              next: () => {
                this.messaging.requestToken.subscribe({
                  next: (token) => {
                    if (token) {
                      this.chatV2Service.updateFCMToken(
                        token as string,
                        lis!.u_id,
                      );
                    }
                  },
                  error: (err) => {
                    console.log('Error while generating token', err);
                  },
                });
                this.messaging.messages.subscribe((payload) => {
                  const notification = new Notification(
                    '🚨🚨🚨New Listener Chat Request🚨🚨🚨',
                    {
                      body: 'You have received a new chat request',
                      image: '/favicon.ico',
                    },
                  );
                  notification.onclick = () => {
                    // window.open("https://google.com")
                  };
                });
              },
              error: (e) => {
                console.log('Permission error', e);
              },
            });
            await this.fetchPendingSummaryChats();
            this.chatV2Service
              .listenerStatusObserver()
              .subscribe((statusUpdate) => {
                const data = statusUpdate.payload.data();
                if (data) {
                  this.listenerOnline =
                    data.listerAvailability?.listenerOnline || false;
                  this.expertOnline =
                    data.listerAvailability?.expertOnline || false;
                  this.listenerObj = data;
                  this.showLoader = false;
                  this.chatButtonLoader = false;
                  this.chatV2Service
                    .chatSessionStatusObserver()
                    .subscribe((docs) => {
                      if (!docs.length) {
                        this.chatSessions = [];
                      }
                      for (let i = 0; i < docs.length; i++) {
                        const doc = docs[i];
                        if (doc.type === 'added') {
                          const data = doc.payload.doc.data() as ChatV2Sessions;
                          const sessionId = doc.payload.doc.id;
                          const session: chatSessionI = {
                            userTyping: data.userTyping || false,
                            listenerRating: data.listenerRating,
                            live: data.live,
                            gender: data.users[0].gender || '',
                            isSelected: false,
                            messages: [],
                            name: data.users[0].name,
                            sessionId: sessionId,
                            userConcerns: data.userConcerns,
                            startTime: moment(data.startTime?.toDate()),
                            userId: data.userIds[0],
                            expertChat: data.expertChat,
                            circleName: data.circleName,
                          };
                          const chatSessionIndex = this.chatSessions.findIndex(
                            (value) => value.sessionId == sessionId,
                          );
                          if (chatSessionIndex == -1) {
                            this.chatSessions.push(session);
                          }
                        }
                      }
                      if (!this.sessionId && this.chatSessions.length) {
                        this.chatSelection(this.chatSessions[0]);
                      }
                    });
                }
              });
            this.chatV2Service.newChatObserver().subscribe((docs) => {
              const chatRequests: newChatI[] = [];
              for (let i = 0; i < docs.length; i++) {
                const doc = docs[i];
                const data = doc.payload.doc.data() as ChatV2NewRequest;
                const requestId = doc.payload.doc.id;
                const newChatRequest: newChatI = {
                  requestId: requestId,
                  email: data.userEmail,
                  gender: data.userGender,
                  specialization: data.specialization,
                  isSelected: false,
                  name: data.userName,
                  expertChat: data.expertChat,
                  circleName: data.circleName,
                };
                const chatRequestIndex = chatRequests.findIndex(
                  (newChatRequest) => newChatRequest.requestId === requestId,
                );
                if (chatRequestIndex == -1) {
                  chatRequests.push(newChatRequest);
                }
              }
              this.chatRequests = chatRequests;
              if (this.chatRequests.length && !this.alertShown) {
                const audio = new Audio('assets/icons/notification sound.mp3');
                audio.play().catch(async (e) => {
                  try {
                    await lastValueFrom(
                      this.api.post(URL_DICT.reportBugUrl, {
                        userId: this.listenerObj?.email,
                        message: 'Unable to play sound in the Listener console',
                        stackString: e,
                        channel: 'ListenersPortal',
                        data: {
                          email: chatRequests
                            .map((value) => value.email)
                            .join(','),
                          chatRequests: chatRequests
                            .map((value) => value.requestId)
                            .join(','),
                        },
                      }),
                    );
                  } catch (e) {
                    console.log('Error sending bug report', e);
                  }
                  console.log('Error playing audio', e);
                });
                this.toastService.success('New chat is waiting');
                this.alertShown = true;
                setTimeout(() => {
                  this.alertShown = false;
                }, 1000);
              }
            });
          }
        });
      }
    });
  }

  async updateExpertAvailabilityStatus($event: Event) {
    const checkBox = $event.target as HTMLInputElement;
    $event.stopPropagation();
    this.chatButtonLoader = true;
    if (checkBox.checked) {
      this.confirmModal = this.modal.confirm({
        nzTitle: 'Do you Want to go online as expert?',
        nzContent: 'Please make sure you want to be online as expert',
        nzOnOk: async () => {
          await this.chatV2Service.setExpertToOnline();
          this.chatButtonLoader = false;
          this.toastService.success('Expert Chat Online');
        },
        nzOnCancel: async () => {
          await this.chatV2Service.setExpertToOffLine(
            this.listenerObj?.listerAvailability.expertNextAvailable,
          );
          this.chatButtonLoader = false;
        },
        nzOkText: 'Go Online',
        nzCancelText: 'Cancel',
        nzClosable: false,
      });
    } else {
      this.confirmModal = this.modal.confirm({
        nzTitle: 'Do you Want to go offline as expert?',
        nzContent:
          'Please make sure you want to be offline as expert. Being offline does not allow you to take the chats',
        nzOnOk: async () => {
          this.showExpertNextAvailableModal = true;
        },
        nzOnCancel: async () => {
          await this.chatV2Service.setExpertToOnline();
          this.chatButtonLoader = false;
        },
        nzOkText: 'Go Offline',
        nzCancelText: 'Cancel',
        nzClosable: false,
      });
    }
  }

  closeExpertNextAvailableModal() {
    this.showExpertNextAvailableModal = false;
  }

  async setExpertNextAvailableFn() {
    if (!this.expertNextAvailableAt) {
      this.toastService.error('Please select next available time');
      return;
    }
    await this.chatV2Service.setExpertToOffLine(this.expertNextAvailableAt);
    this.chatButtonLoader = false;
    this.showExpertNextAvailableModal = false;
    this.toastService.error('Expert Chat Offline');
  }

  nextAvailableTimeSelected($event: Date) {
    if (moment($event).isBefore(moment())) {
      this.toastService.error('Please select a future time');
      return;
    }
    this.expertNextAvailableAt = $event;
  }
}

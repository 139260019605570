import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ExpertsRoutingModule } from './experts-routing.module';
import { ManageExpertsComponent } from './manage-experts/manage-experts.component';
import { EditExpertsComponent } from './edit-experts/edit-experts.component';
import { AddExpertsComponent } from './add-experts/add-experts.component';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzRateModule } from 'ng-zorro-antd/rate';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { CrudExpertsComponent } from './crud-experts/crud-experts.component';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';



@NgModule({
  declarations: [
    ManageExpertsComponent,
    EditExpertsComponent,
    AddExpertsComponent,
    CrudExpertsComponent
  ],
  imports: [
    CommonModule,
    ExpertsRoutingModule,
    NzDividerModule,
    NzTableModule,
    NzGridModule,
    NzSpinModule,
    NzButtonModule,
    NzToolTipModule,
    NzIconModule,
    NzSelectModule,
    FormsModule,
    NzInputModule,
    NzSwitchModule,
    NzRateModule,
    NzUploadModule,
    ReactiveFormsModule,
    NzDropDownModule,
    NzTagModule,
    NzCheckboxModule
  ]
})
export class ExpertsModule { }

import {Component} from '@angular/core';

import {AuthService} from '../../../app-core/src/auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'listener-portal';

  constructor(auth: AuthService) {
    auth.currentAuthStatus.subscribe((user) => {

    });
  }
}

<div *ngIf="rejectAlert">
  <app-alert-box
    (closeAlert)="closeRejectAlert()"
    (rejectReason)="reject($event)"
    [alertHeader]="rejectHeader"
    [isContent]="true"
    [isReject]="true"
    [rejectAlertBody]="rejectReason"
  ></app-alert-box>
</div>
<div class="flex flex-row md:gap-2">
  <div nz-col>
    <nz-card>
      <label class="client-initial text-black-">Listener Chat</label>
      <div class="flex flex-nowrap box">
        <input
          (change)="updateListenerAvailabilityStatus($event)"
          [(ngModel)]="listenerOnline"
          class="switch switch-success"
          id="online-checkbox"
          type="checkbox"
        />
      </div>
    </nz-card>
  </div>
  <div nz-col>
    <nz-card>
      <label class="client-initial text-black-">Expert Chat</label>
      <div class="flex flex-nowrap box">
        <input
          (change)="updateExpertAvailabilityStatus($event)"
          [(ngModel)]="expertOnline"
          class="switch switch-success"
          id="expert-online-checkbox"
          type="checkbox"
        />
      </div>
    </nz-card>
  </div>
</div>

<div *ngIf="rateAlert">
  <app-alert-box
    (feedbackRate)="feedRate($event)"
    [alertHeaderRating]="ratingHeader"
    [isRating]="true"
    [nameOfUser]="rateName"
  ></app-alert-box>
</div>

<div class="mt-5" nz-row nzJustify="space-evenly">
  <div
    class="max-h-[85vh] min-h-[85vh]"
    nz-col
    nzSpan="6"
    style="overflow: auto"
  >
    <nz-card [nzLoading]="showLoader">
      <nz-collapse
        *ngIf="
          chatSessions.length ||
          chatRequests.length ||
          pendingSummaryChats.length
        "
      >
        <nz-collapse-panel
          *ngIf="chatSessions.length"
          nzActive="true"
          nzHeader="Active Chats"
        >
          <div *ngFor="let item of chatSessions; index as index">
            <nz-card (click)="chatSelection(item)">
              {{ item.name }} ( {{ item.gender }})
              <div nz-row nzJustify="start">
                <div *ngFor="let sp of item.userConcerns; index as spIndex">
                  <nz-tag [nzColor]="'magenta'">{{ sp }}</nz-tag>
                </div>
              </div>
            </nz-card>
          </div>
        </nz-collapse-panel>
        <nz-collapse-panel
          *ngIf="chatRequests.length"
          nzActive="true"
          nzHeader="New Chat Requests"
        >
          <div *ngFor="let item of chatRequests; index as index">
            <nz-card>
              <nz-tag [nzColor]="'red'" *ngIf="item.expertChat"
                >Expert Chat</nz-tag
              >
              {{ item.name }} ({{ item.gender }})
              <div nz-row nzJustify="start" *ngIf="item.specialization.length">
                <div *ngFor="let sp of item.specialization; index as spIndex">
                  <nz-tag [nzColor]="'magenta'">{{ sp }}</nz-tag>
                </div>
              </div>
              <div nz-row nzJustify="start" *ngIf="item.circleName">
                <nz-tag [nzColor]="'magenta'">{{ item.circleName }}</nz-tag>
              </div>
              <div class="mt-5" nz-row>
                <div nz-col>
                  <button
                    (click)="confirm(item, index)"
                    nz-button
                    nzType="primary"
                  >
                    <i nz-icon nzType="check"></i>
                    Accept Chat
                  </button>
                </div>
                <div nz-col>
                  <button
                    (click)="cancel(item.requestId)"
                    nz-button
                    nzDanger
                    nzType="primary"
                  >
                    <i nz-icon nzType="check"></i>
                    Reject Chat
                  </button>
                </div>
              </div>
            </nz-card>
          </div>
        </nz-collapse-panel>
        <nz-collapse-panel
          *ngIf="pendingSummaryChats.length"
          nzActive="true"
          nzHeader="Pending Feedback"
        >
          <div *ngFor="let item of pendingSummaryChats; index as index">
            <nz-card (click)="showSessionSummary(item.sessionId)">
              <div nz-row nzJustify="start">
                {{ item.userName }}
              </div>
              <div nz-row nzJustify="start">
                {{ item.startTime }} ({{ item.chatTime }})
              </div>
              <div nz-row nzJustify="start">
                <div *ngFor="let sp of item.userConcern; index as spIndex">
                  <nz-tag [nzColor]="'magenta'">{{ sp }}</nz-tag>
                </div>
              </div>
            </nz-card>
          </div>
        </nz-collapse-panel>
      </nz-collapse>
      <nz-empty
        *ngIf="
          !(
            chatSessions.length ||
            chatRequests.length ||
            pendingSummaryChats.length
          )
        "
        class="min-h-[75vh]"
        nzNotFoundContent="No Chats"
      ></nz-empty>
    </nz-card>
  </div>
  <div nz-col nzSpan="18">
    <nz-card
      *ngIf="showSessionPane"
      [nzLoading]="showLoader"
      class="max-h-[85vh]"
    >
      <div nz-row>
        <div class="overflow-atuo" nz-col nzSpan="24">
          <div class="max-h-[600px] overflow-auto">
            <b>Chat History</b>
            <nz-divider></nz-divider>
            <nz-card
              #chatContainer
              [infiniteScrollDistance]="2"
              [infiniteScrollThrottle]="50"
              [scrollWindow]="true"
              class="max-h-[300px] overflow-auto pr-2"
              infiniteScroll
            >
              <div *ngFor="let item of oldMessages; index as index">
                <div
                  *ngIf="item.messageType === 'User'"
                  class="flex justify-start"
                >
                  <div class="mx-1 serverBorder flex pr-3">
                    <div class="min-w-[100px] chatMessage">
                      {{ item.message }}
                    </div>
                  </div>
                </div>
                <div
                  *ngIf="item.messageType === 'User'"
                  [id]="index + sessionId"
                  class="mx-3 timeChat flex justify-start"
                >
                  {{ formatDateToTimeString(item.sentAt) }}
                </div>
                <!-- </div> -->
                <!-- <div *ngFor="let item of selectedUser?.messages"> -->
                <div
                  *ngIf="item.messageType === 'Listener'"
                  class="flex justify-end"
                >
                  <div class="chatBorder flex pr-3">
                    <div class="min-w-[100px] chatMessage">
                      {{ item.message }}
                    </div>
                  </div>
                </div>
                <div
                  *ngIf="item.messageType === 'Listener'"
                  [id]="index + sessionId"
                  class="flex justify-end timeChat"
                >
                  {{ formatDateToTimeString(item.sentAt) }}
                </div>
              </div>
            </nz-card>
            <app-session-feedback
              (sessionUpdateComplete)="sessionUpdateComplete()"
              [sessionId]="submitFeedbackSessionId"
            ></app-session-feedback>
          </div>
        </div>
      </div>
    </nz-card>
    <nz-card *ngIf="!showSessionPane" class="max-h-[85vh]">
      <div class="card-chat">
        <div class="card-body-chat max-h-[90%]">
          <!-- <div *ngIf="chatHeader" > -->
          <div class="flex flex-col justify-between md:gap-3">
            <div
              *ngIf="chatHeader"
              [ngClass]="{
                'disabled-card': !this.listenerOnline && !this.expertOnline
              }"
            >
              <div class="chat-header">
                <div *ngIf="chatName">
                  <div class="flex flex-row justify-between">
                    <div
                      class="flex flex-row md:gap-1 items-center justify-center"
                    >
                      <div class="nameChat">{{ chatName }}</div>
                      <br />
                      <div
                        *ngIf="selectedUserConcerns.length"
                        nz-row
                        nzJustify="start"
                      >
                        <div
                          *ngFor="
                            let sp of selectedUserConcerns;
                            index as spIndex
                          "
                        >
                          <nz-tag [nzColor]="'magenta'">{{ sp }}</nz-tag>
                        </div>
                      </div>
                      <div
                        *ngIf="selectedUser?.circleName"
                        nz-row
                        nzJustify="start"
                      >
                        <nz-tag [nzColor]="'magenta'">{{
                          selectedUser?.circleName
                        }}</nz-tag>
                      </div>
                    </div>
                    <div
                      class="flex flex-row md:gap-2 items-center justify-center"
                    >
                      <div class="clock-container">
                        {{ chatDuration }}
                      </div>
                      <button
                        (click)="showHistoryDrawerFn()"
                        nz-button
                        nzType="primary"
                      >
                        Show History
                      </button>
                      <div>
                        <button
                          (click)="end()"
                          nz-button
                          nzDanger
                          nzType="primary"
                        >
                          End Chat
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              [ngClass]="{ 'disabled-card': !listenerOnline && !expertOnline }"
            >
              <div *ngIf="chatName" class="overflow-auto" style="height: 60vh">
                <div
                  #chatContainer
                  [infiniteScrollDistance]="2"
                  [infiniteScrollThrottle]="50"
                  [scrollWindow]="true"
                  class="chat-container overflow-auto pr-2"
                  infiniteScroll
                >
                  <div
                    *ngFor="let item of selectedUser?.messages; index as index"
                  >
                    <div
                      *ngIf="item.messageType === 'User'"
                      class="flex justify-start"
                    >
                      <div class="mx-1 serverBorder flex pr-3">
                        <div class="min-w-[100px] chatMessage">
                          {{ item.message }}
                        </div>
                      </div>
                    </div>
                    <div
                      *ngIf="item.messageType === 'User'"
                      [id]="index + sessionId"
                      class="mx-2 timeChat flex justify-start"
                    >
                      {{ formatDateToTimeString(item.sentAt) }}
                    </div>
                    <!-- </div> -->
                    <!-- <div *ngFor="let item of selectedUser?.messages"> -->
                    <div
                      *ngIf="item.messageType === 'Listener'"
                      class="flex justify-end"
                    >
                      <div class="chatBorder flex pr-3">
                        <div class="min-w-[100px] chatMessage">
                          {{ item.message }}
                        </div>
                      </div>
                    </div>
                    <div
                      *ngIf="item.messageType === 'Listener'"
                      [id]="index + sessionId"
                      class="flex justify-end timeChat"
                    >
                      {{ formatDateToTimeString(item.sentAt) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <nz-empty
              *ngIf="!chatName"
              class="min-h-[75vh]"
              nzNotFoundContent="Messages will appear here"
            ></nz-empty>
            <div
              *ngIf="chatName"
              [ngClass]="{
                'disabled-card': !this.listenerOnline && !expertOnline
              }"
            >
              <span class="mx-3 my-1" *ngIf="selectedUser?.userTyping"
                >User is typing .....</span
              >
              <div class="chat-sending-box">
                <div
                  class="flex flex-grow w-1/2 py-[10px] md:pl-4 relative border bg-white rounded-xl min-h-50"
                >
                  <textarea
                    #inputMessage
                    (input)="autoExpandInput($event.target)"
                    (keypress)="onKeyPress($event)"
                    (keydown)="onKeyDown($event)"
                    [(ngModel)]="currentMessage"
                    class="content text-black"
                    placeholder="Please type your message..."
                    rows="1"
                  ></textarea>
                  <button
                    (click)="sendMessage(sessionId)"
                    [disabled]="!currentMessage.trim()"
                    class=""
                  >
                    <img
                      class="chatImage"
                      src="assets/icons/message-send.svg"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- </div> -->
        </div>
      </div>
    </nz-card>
  </div>
</div>
<nz-drawer
  (nzOnClose)="hideHistoryDrawerFn()"
  [nzClosable]="true"
  [nzVisible]="showHistoryDrawer"
  [nzWidth]="600"
  nzPlacement="bottom"
  nzSize="large"
  nzTitle="History"
>
  <ng-container *nzDrawerContent>
    <nz-table
      #historyTable
      [nzBordered]="true"
      [nzData]="chatSummaryList"
      nzFrontPagination="true"
    >
      <thead>
        <tr>
          <th>Date</th>
          <th>Listener</th>
          <th>Chat Summary</th>
          <th>Key Actions</th>
          <th>Therapy Suggested</th>
          <th>Issue Profile</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let data of historyTable.data">
          <tr>
            <td>{{ data.summaryCompletedOnString }}</td>
            <td>{{ data.listenerName }}</td>
            <td>{{ data.summary }}</td>
            <td>{{ data.keyActions }}</td>
            <td>{{ data.therapySuggestedString }}</td>
            <td>
              <div *ngFor="let sp of data.issueProfileFlat; index as spIndex">
                <nz-tag [nzColor]="'magenta'">{{ sp }}</nz-tag>
              </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </nz-table>
  </ng-container>
</nz-drawer>
<nz-modal
  (nzOnCancel)="closeExpertNextAvailableModal()"
  (nzOnOk)="setExpertNextAvailableFn()"
  [(nzVisible)]="showExpertNextAvailableModal"
  nzTitle="The first Modal"
>
  <ng-container *nzModalContent>
    Please set your Next Available Time
    <nz-date-picker
      (ngModelChange)="nextAvailableTimeSelected($event)"
      ngModel
      nzFormat="yyyy-MM-dd HH:mm:ss"
      nzShowTime
    ></nz-date-picker>
  </ng-container>
</nz-modal>

<div class="listenercontainer flex h-screen flex-row flex-start h-full">
  <div class="flex main-menu border-r-2 border-solid-grey">
    <app-menu [menuArray]="menu"></app-menu>
  </div>
  <div class="flex flex-col flex-start w-full">
    <div>
      <app-common-header [user]="user"></app-common-header>
    </div>
    <div class="contentlistener h-screen w-full md:p-4">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { ListenerAuthRoutingModule } from './listener-auth-routing.module';
import { ListenerLayoutComponent } from './listener-layout/listener-layout.component';
import { ListenerLoginComponent } from './listener-login/listener-login.component';
import { ListenerSetupComponent } from './listener-setup/listener-setup.component';
import {
  CommonComponentModule,
  HttpLoaderFactory,
} from 'projects/app-core/src/app/components/commoncomponent.module';
import { ListenerForgotPasswordComponent } from './listener-forgot-password/listener-forgot-password.component';

@NgModule({
  declarations: [
    ListenerLayoutComponent,
    ListenerLoginComponent,
    ListenerSetupComponent,
    ListenerForgotPasswordComponent,
  ],
  imports: [
    CommonModule,
    ListenerAuthRoutingModule,
    CommonComponentModule,
    RouterModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
})
export class ListenerAuthModule {}

import {Timestamp} from 'firebase/compat/firestore/dist/firestore';

export interface ChatV2Messages {
  message: string;
  senderId: string;
  senderType: ChatUserTypes;
  timestamp: Timestamp | Date;
}
export enum ChatUserTypes {
  Listener = 'Listener',
  User = 'User',
}

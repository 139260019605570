<div>
  <nz-spin [nzSpinning]="loading">
    <b>Session Summary</b>
    <nz-divider></nz-divider>
    <nz-card>
      <div nz-row nzGutter="12">
        <div nz-col nzSpan="12">
          <div
            *ngFor="let item of issueProfiles; index as index"
            class="mt-2"
            nz-row
            nzAlign="middle"
            nzGutter="12"
            nzJustify="start"
          >
            <div nz-col>
              <nz-select
                (ngModelChange)="categorySelectedFn($event)"
                [(ngModel)]="item.concernId"
                nzPlaceHolder="Concern"
              >
                <nz-option
                  *ngFor="let item of categorySelect"
                  [nzLabel]="item.label"
                  [nzValue]="item.id"
                >{{ item.label }}
                </nz-option
                >
              </nz-select>
            </div>
            <div nz-col>
              <nz-select
                [(ngModel)]="item.subConcernId"
                nzPlaceHolder="Sub Concern"
              >
                <nz-option
                  *ngFor="let item of subCategorySelect"
                  [nzLabel]="item.label"
                  [nzValue]="item.id"
                >{{ item.label }}
                </nz-option
                >
              </nz-select>
            </div>
            <div *ngIf="index === issueProfiles.length - 1" nz-col>
              <button
                (click)="addIssueProfile()"
                nz-button
                nzShape="circle"
                nzSize="small"
                nzType="primary"
              >
                +
              </button>
            </div>
            <div *ngIf="issueProfiles.length > 1" nz-col>
              <button
                (click)="deleteIssueProfile(index)"
                nz-button
                nzDanger
                nzShape="circle"
                nzSize="small"
                nzType="primary"
              >
                <span nz-icon nzTheme="outline" nzType="close"></span>
              </button>
            </div>
          </div>
          <textarea
            [(ngModel)]="chatSummary"
            [nzAutosize]="{ minRows: 3, maxRows: 6 }"
            class="mt-5"
            nz-input
            placeholder="Chat Summary"
            rows="4"
          ></textarea>

          <label [(ngModel)]="therapySuggested" class="mt-5" nz-checkbox
          >Therapy Suggested</label
          >
        </div>
        <div nz-col nzSpan="12">
          <textarea
            [(ngModel)]="keyActionsSuggested"
            [nzAutosize]="{ minRows: 3, maxRows: 6 }"
            nz-input
            placeholder="Key Actions Suggested"
            rows="4"
          ></textarea>
        </div>
      </div>
      <nz-divider></nz-divider>
      <div class="mt-5" nz-row nzJustify="end">
        <div nz-col nzSpan="3">
          <button (click)="submitFeedback()" nz-button nzType="primary">
            Submit
          </button>
        </div>
      </div>
    </nz-card>
  </nz-spin>
</div>
